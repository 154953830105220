import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  Checkbox,
  ScrollView,
  Image,
} from "native-base";

function Insights({ navigation, route }) {
  return (
    <ScrollView h={800}>
      <Center>
        <Container py="3">
          <VStack space="3">
            <Heading>Insights</Heading>
            <Text>
              Nice work on capturing your first of many insights! We’ll store
              these insights for you so that you can refer back to them later.
            </Text>
            <Text>
              With each new insight you are building your emotional intelligence
              or self-awareness, which is key to long term behaviour change.
            </Text>
            <Text>Now let’s learn a new skill!</Text>
            <Button
              variant="outline"
              onPress={() => {
                navigation.push("DefiningAchievableTasks", {
                  screen: "TaskFeatures",
                });
              }}
            >
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Insights;
