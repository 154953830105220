import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  Checkbox,
  ScrollView,
  Image,
} from "native-base";
import { track } from "@amplitude/analytics-react-native";
import { useStore } from "../../state";

function Commitment({ navigation, route }) {
  const [checklist, setChecklist] = React.useState([]);
  const completeModule = useStore((state) => state.completeModule);
  return (
    <ScrollView>
      <Center py="3">
        <Container py="3">
          <VStack space="3">
            <Heading>Make a commitment to change</Heading>
            <Text>
              You’ve completed the first and most necessary part of overcoming
              procrastination - understanding your procrastination cycle!
            </Text>
            <Text>
              You are now ready to learn the most effective, science based
              strategies for changing your procrastination habits.
            </Text>
            <Text>The last thing to do is make a commitment to change:</Text>

            <Checkbox.Group
              defaultValue={[]}
              accessibilityLabel="I commit to:"
              onChange={(values) => {
                setChecklist(values || []);
              }}
              mb="3"
            >
              {[
                "I commit to being honest with myself ",
                "I commit to reflect for 1 minute a day",
                "I commit to making a change",
              ].map((item) => (
                <Checkbox key={`checklist-${item}`} value={item}>
                  {item}
                </Checkbox>
              ))}
            </Checkbox.Group>
            <Button
              isDisabled={checklist.length < 3}
              onPress={() => {
                completeModule(4);
                track("Exercise Completed", { exerciseNumber: 4 });
                navigation.reset({
                  index: 1,
                  routes: [
                    { name: "Journey" },
                    {
                      name: "Home",
                      params: { fireConfettiCannon: true, exerciseNumber: 4 },
                    },
                  ],
                });
              }}
            >
              Finish
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Commitment;
