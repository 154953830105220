

import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  Checkbox,
  ScrollView,
  Image,
} from "native-base";

function Keeps({ navigation, route }) {
  return (
    <ScrollView>
      <Center py="3">
        <Container py="3">
          <VStack space="3">
            <Heading>What keeps us procrastinating?</Heading>
            <Text>Procrastinating important tasks has many outcomes. Some are positive, like finding amusing videos while scrolling social media. Others are negative, like a feeling of guilt or shame that builds up when we put off an important task. In this exercise you’ll learn how those outcomes drive you to keep procrastinating. </Text>
            <Text>Behavioral psychologists call the outcomes that make us do more of something reinforcement factors. Understanding reinforcement factors is the final piece in the puzzle of your procrastination.</Text>
            <Button
              variant="outline"
              onPress={() => {
                navigation.push("ReinforcementFactors", {
                  screen: "Common",
                });
              }}
            >
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Keeps;