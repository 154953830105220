import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  Checkbox,
  ScrollView,
  Image,
} from "native-base";
import image from "../../assets/cycle.jpg";

function Explanation({ navigation, route }) {
  return (
        <ScrollView h={800}>
    <Center>
      <Container py="3">
          <VStack space="3">
            <Heading>How procrastination persists! </Heading>
            <Text>The excuses or “rationalisations” you identified are an important part of the cycle of procrastination.</Text>
            <Text>Procrastination is a habit that we learn in order to manage discomfort. This diagram outlines a basic model of procrastination.</Text>
            <Image source={image} height={320} width={320} alt="Procrastination reinforcement loop" />
            <Text>When we think about doing certain tasks, we experience sudden, often unconscious, flashes of discomfort.</Text>
            <Text>Naturally we want to avoid that feeling! So we create rationalisations for not doing the task. Those excuses make us feel a little bit better about putting off the task.</Text>
            <Text>After we decide to put off the task for a bit longer our discomfort goes away for a moment! In psychology this is called a reinforcement loop. It means that the next time we face a difficult task we use this strategy again.</Text>
            <Text>Understanding this reinforcement loop is the key to overcoming procrastination.</Text>
            <Button
              variant="outline"
              onPress={() => {
                navigation.push("UnderstandingProcrastination", { screen: "FirstStep" });
              }}
            >
              Continue
            </Button>
          </VStack>
      </Container>
    </Center>
        </ScrollView>
  );
}

export default Explanation;
