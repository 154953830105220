import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  Checkbox,
  ScrollView,
} from "native-base";
import { useStore } from "../../state";

function IdentifyDistractions({ navigation, route }) {
  const setDistractions = useStore(state => state.setDistractions);
  return (
    <ScrollView>
      <Center>
        <Container py="3" w="full">
          <VStack space="3" w="full">
            <Heading mt="3">What are your procrastination activities?</Heading>
            <VStack space="3">
              <Text>
                Select the activities that you find yourself doing when you
                procrastinate.
              </Text>
              <Checkbox.Group
                defaultValue={[]}
                accessibilityLabel="pick tasks"
                onChange={(values) => {
                  setDistractions(values || []);
                }}
              >
                {[
                  "Gaming",
                  "Netflix, youtube etc.",
                  "Social media, instagram, tik tok etc.",
                  "Reading ",
                  "Online shopping",
                  "Tidying / organising ",
                  "Checking messages and emails",
                  "Less important work tasks",
                  "Sleeping ",
                  "Exercising",
                  "Eating",
                  "Drinking, smoking, other drugs",
                  "Masturbating",
                  "Seeing friends, family, partner",
                  "Thinking about the past or future ",
                  "Imagining the task/ goal is already finished",
                ].map((item) => (
                  <Checkbox key={item} value={item}>
                    {item}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </VStack>
            <Button
              variant="outline"
              onPress={() => {
                navigation.push("UnderstandingProcrastination", {
                  screen: "Rationalisations",
                });
              }}
            >
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default IdentifyDistractions;
