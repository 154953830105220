import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  ScrollView,
  Image,
} from "native-base";

function IntoleranceOfLowEnergy({ navigation, route }) {
  return (
    <ScrollView>
      <Center py="3">
        <Container py="3">
          <VStack space="3">
            <Heading>Intolerance of low energy</Heading>
            <Text>Some people believe they can only complete tasks and fulfill goals when they "feel like it". These people struggle when faced with a task when their energy is low. They can feel exhausted, disparaging or frustrated because they believe they can’t do it.</Text>
            <Text>These people are always waiting to feel motivated or inspired. They don't think they are capable of progressing when life becomes tough. They tell themselves "I can only do this if I am in the mood". They assume they will make things worse if they try when feeling stressed, fatigued or unmotivated. </Text>
            <Text>These people procrastinate to rebuild their energy and get rid of their exhaustion. They assume that if they rest rather than do, things will somehow get better. </Text>
            <Button
              variant="outline"
              onPress={() => { navigation.goBack(); }}
            >
              Back
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default IntoleranceOfLowEnergy;