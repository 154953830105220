import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  Checkbox,
  ScrollView,
  Image,
} from "native-base";

function TaskFeatures({ navigation, route }) {
  return (
        <ScrollView h={800}>
    <Center>
      <Container py="3">
          <VStack space="3">
            <Heading>Task features that make us procrastinate</Heading>
            <Text>
              Everyone’s experience of procrastination is slightly different.
              Nonetheless, there are some common patterns or “features” of tasks
              that make us more prone to procrastinate them. Today we are going
              to learn a few of them.
            </Text>
            <Button
            variant="outline"
              onPress={() => {
                navigation.push("DefiningAchievableTasks", {
                  screen: "Unclear",
                });
              }}
            >
              Continue
            </Button>
          </VStack>
      </Container>
    </Center>
        </ScrollView>
  );
}

export default TaskFeatures;
