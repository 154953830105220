import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  Checkbox,
  ScrollView,
  Image,
} from "native-base";
import { useStore } from "../../state";
import { track } from "@amplitude/analytics-react-native";

function Mission({ navigation, route }) {
  const completeModule = useStore((state) => state.completeModule);
  return (
    <ScrollView h={800}>
      <Center>
        <Container py="3">
          <VStack space="3">
            <Heading>Your mission this week!</Heading>
            <Text>Each time you record a task we’ll make sure it is:</Text>
            <Text>• Specific</Text>
            <Text>
              • Small enough that you can cross the “starting threshold”
            </Text>
            <Button
              onPress={() => {
                completeModule(5);
                track("Exercise Completed", { exerciseNumber: 5 });
                navigation.reset({
                  index: 1,
                  routes: [
                    { name: "Journey" },
                    {
                      name: "Home",
                      params: { fireConfettiCannon: true, exerciseNumber: 5 },
                    },
                  ],
                });
              }}
            >
              Finish
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Mission;
