import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  Checkbox,
  ScrollView,
  Image,
} from "native-base";

function Why({ navigation, route }) {
  return (
    <ScrollView h={800}>
      <Center py="3">
        <Container py="3">
          <VStack space="3">
            <Heading>Why are these beliefs unhelpful?</Heading>
            <Text>The beliefs you identified are unhelpful because they are inaccurate or inflexible. </Text>
            <Text>Your procrastination is able to persist because your core beliefs are never challenged. For instance, you might believe “if I try to do this when I don’t feel motivated, then I won’t make any progress”. This belief might be completely false! But you will never know because you don't try when you're feeling unmotivated.</Text>
            <Text>When you start a task, the core beliefs you identified get activated. Once activated, they make the task seem aversive. You feel discomfort at the mere thought of starting.</Text>
            <Button
              variant="outline"
              onPress={() => {
                navigation.push("Beliefs", {
                  screen: "Notice",
                });
              }}
            >
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Why;