import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  TextArea,
  VStack,
  Checkbox,
  ScrollView,
  KeyboardAvoidingView,
  Image,
  Box,
} from "native-base";
import { useStore } from "../../state";
import { useColorScheme } from "react-native";

function Relieving({ navigation, route }) {
  const logEntries = useStore(state => state.logEntries)
  const scheme = useColorScheme()
  return (
    <ScrollView>
      <KeyboardAvoidingView behavior="padding">
      <Center py="3">
        <Container py="3">
          <VStack space="3">
            <Heading>Relieving discomfort</Heading>
            <Text>Here are the sensations you recorded from your last five reflections.</Text>
              {logEntries
                .sort(
                  (a, b) =>
                    new Date(b.createdAt).getTime() -
                    new Date(a.createdAt).getTime()
                )
                .splice(0, 5)
                .map((entry) => (
                  <Box
                    key={entry.createdAt}
                    borderWidth="1"
                    borderColor={
                      scheme === "dark" ? "coolGray.600" : "coolGray.300"
                    }
                    backgroundColor={
                      scheme === "dark" ? "coolGray.600" : "coolGray.300"
                    }
                    p="5"
                    rounded="8"
                  >
                    <Text>{entry.sensation}</Text>
                  </Box>
                ))}
            <Text>Chances are there are some pretty uncomfortable sensations here. Each time that you procrastinate you experience temporary relief from that discomfort.</Text>
            <Text>That relief is a positive consequence that keeps you procrastinating.</Text>
            <Button
              variant="outline"
              onPress={() => {
                navigation.push("ReinforcementFactors", {
                  screen: "Pleasure",
                });
              }}
            >
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
      </KeyboardAvoidingView>
    </ScrollView>
  );
}

export default Relieving;