import * as React from "react";
import { useStore } from "../state";
import {
  Box,
  Button,
  Center,
  Container,
  VStack,
  Heading,
  Text,
  Pressable,
  Badge,
  HStack,
  Spacer,
  Flex,
  Progress,
  ScrollView,
  Image,
  Link,
} from "native-base";
import { track } from "@amplitude/analytics-react-native";
import ConfettiCannon from "react-native-confetti-cannon";
import today from "../assets/today.png";
import treasure from "../assets/treasure.png";
import memory from "../assets/memory.png";
import winners from "../assets/winners.png";
import choose from "../assets/choose.png";
import { MaterialIcons, MaterialCommunityIcons } from "@expo/vector-icons";
import { useColorScheme } from "react-native";

function HomeScreen({ navigation, route }) {
  const moduleCompletions = useStore((state) => state.moduleCompletions);
  const logEntries = useStore((state) => state.logEntries);
  const dates = logEntries.map((entry) =>
    new Date(entry.createdAt).toDateString()
  );
  const uniqueDates = [...new Set(dates)];

  const confettiCannon = React.useRef(null);
  const scheme = useColorScheme();

  const currentExercise = route?.params?.exerciseNumber
    ? route.params.exerciseNumber
    : Math.floor(uniqueDates.length / 7) +
      1 +
      moduleCompletions.find((m) => (m.number === 4 ? 1 : 0));

  const currentExerciseStarted = moduleCompletions.find(
    (m) => Number(m.number) === currentExercise
  );

  const currentExerciseProgress = Math.max(
    uniqueDates.length -
      7 *
        (currentExercise -
          (currentExercise > 3
            ? moduleCompletions.find((m) => m.number === 4)
              ? 2
              : 1
            : 1)),
    0
  );

  const reflectedToday = uniqueDates.find(
    (d) => d === new Date().toDateString()
  );

  React.useEffect(() => {
    track("Homepage Viewed", { exerciseNumber: currentExercise });
    if (route.params?.fireConfettiCannon) {
      confettiCannon?.current?.start();
    }
  }, [route]);

  const renderTask = ({
    taskDescription,
    taskCTA,
    taskAction,
    taskDuration,
  }) => {
    return (
      <Box mt={3} borderWidth="1" borderColor="coolGray.300" p="5" rounded="8">
        <HStack alignItems="center" mb={3}>
          <Badge
            colorScheme={""}
            _text={{
              color: "white",
            }}
            variant="solid"
            rounded="4"
          >
            Today
          </Badge>
          <Spacer />
          <Text fontSize={10}>{taskDuration}</Text>
        </HStack>
        <Text mb={3}>{taskDescription}</Text>
        <Button onPress={taskAction}>{taskCTA}</Button>
      </Box>
    );
  };

  const renderTaskComplete = ({ exerciseProgress }) => (
    <Box mt={3} borderWidth="1" borderColor="coolGray.300" p="5" rounded="8">
      <HStack alignItems="center" mb={3}>
        <Badge
          colorScheme={""}
          _text={{
            color: "white",
          }}
          variant="solid"
          rounded="4"
        >
          Today
        </Badge>
        <Spacer />
      </HStack>
      {exerciseProgress > 0 ? (
        <>
          <Text mb={3}>You've done today's reflection – well done!</Text>
          <Text mb={3} fontSize="14">
            Reflect again tomorrow to continue gaining awareness of your
            procrastination reinforcement loop.
          </Text>
        </>
      ) : (
        <>
          <Text mb={3}>Come back tomorrow to add a reflection</Text>
        </>
      )}
    </Box>
  );

  const renderExercise = ({
    exerciseNumber,
    exerciseTitle,
    exerciseProgress,
    exerciseStarted,
    route,
  }) => {
    if (exerciseProgress > 7) {
      exerciseProgress = 7;
    }
    return (
      <>
        <Heading>{exerciseTitle}</Heading>
        {exerciseStarted && (
          <Link
            mt={-3}
            mb={6}
            isUnderlined={false}
            _text={{
              _light: {
                color: "primary.600",
              },
              color: "primary.400",
            }}
            onPress={() => navigation.push(route)}
          >
            Review lesson
            <MaterialCommunityIcons
              name="chevron-right"
              size={28}
              color={scheme === "dark" ? "white" : "black"}
            />
          </Link>
        )}
        {exerciseProgress !== null && (
          <>
            <Text mt={-3} fontSize={14}>
              {exerciseProgress < 7
                ? "Reflect on a task you are procrastinating each day for 7 days"
                : "You've completed this topic! 🎉"}
            </Text>
            <Flex flexDir="row" justifyContent="space-between">
              {[...Array(exerciseProgress)].map((_, index) => (
                <Box
                  key={`progress-complete-${index}`}
                  h="8"
                  w="8"
                  borderWidth="4"
                  borderColor="primary.600"
                  backgroundColor="primary.600"
                  rounded="26"
                  alignItems="center"
                  justifyContent="center"
                  color="white"
                ></Box>
              ))}
              {[...Array(7 - exerciseProgress)].map((_, index) => (
                <Box
                  key={`progress-incomplete-${index}`}
                  h="8"
                  w="8"
                  borderWidth="4"
                  borderColor="primary.600"
                  rounded="26"
                  alignItems="center"
                  justifyContent="center"
                  opacity="0.5"
                ></Box>
              ))}
            </Flex>
          </>
        )}
      </>
    );
  };

  const exercises = [
    {
      exerciseNumber: "LESSON ONE",
      exerciseTitle: "Understanding your procrastination",
      exerciseDescription: `This week's lesson will help build your awareness of the "reinforcement loop" driving your procrastination.`,
      route: "UnderstandingProcrastination",
      image: today,
    },
    {
      exerciseNumber: "LESSON TWO",
      exerciseTitle: "Uncover your core beliefs",
      exerciseDescription:
        "This week you’ll learn why certain tasks make you uncomfortable. You’ll uncover the core beliefs fueling your procrastination.",
      route: "Beliefs",
      image: treasure,
    },
    {
      exerciseNumber: "LESSON THREE",
      exerciseTitle: "Understand your reinforcement factors",
      exerciseDescription:
        "Procrastinating important tasks has both positive and negative outcomes. In this exercise you’ll learn how those outcomes drive you to keep procrastinating.",
      route: "ReinforcementFactors",
      image: memory,
    },
    {
      exerciseNumber: "LESSON FOUR",
      exerciseTitle: "Commit to change",
      exerciseDescription:
        "In this exercise we’ll introduce the four strategies for breaking the procrastination cycle. We’ll also assess your readiness for change to set you up for success.",
      route: "Change",
      image: winners,
      dimensions: {
        width: 240,
        height: 240,
      },
    },
    {
      exerciseNumber: "LESSON FIVE",
      exerciseTitle: "Get past the starting threshold",
      exerciseDescription:
        "In this exercise you’ll learn one of the most effective strategies for overcoming procrastination",
      route: "DefiningAchievableTasks",
      image: choose,
      dimensions: {
        width: 212,
        height: 220,
      },
    },
  ];

  return (
    <>
      <ScrollView>
        <Center py="6">
          <Image
            mt={4}
            source={exercises[currentExercise - 1].image}
            width={
              exercises[currentExercise - 1].dimensions
                ? exercises[currentExercise - 1].dimensions.width
                : "300"
            }
            height={
              exercises[currentExercise - 1].dimensions
                ? exercises[currentExercise - 1].dimensions.height
                : "250"
            }
            alt="Procrastination App"
          />
          <Container py="6" w="full">
            <VStack space="4" w="full">
              {renderExercise({
                ...exercises[currentExercise - 1],
                exerciseProgress: currentExerciseStarted
                  ? currentExerciseProgress
                  : null,
                exerciseStarted: currentExerciseStarted,
              })}
              {currentExerciseProgress < 7 &&
                (currentExerciseStarted
                  ? reflectedToday
                    ? renderTaskComplete({
                        exerciseProgress: currentExerciseProgress,
                      })
                    : renderTask({
                        taskDescription:
                          "Reflect on one task you're procrastinating today",
                        taskCTA: "Reflect",
                        taskAction: () => {
                          track("Task Started");
                          navigation.navigate("Reflect", { screen: "What" });
                        },
                        taskDuration: "40 SECONDS",
                      })
                  : renderTask({
                      taskDescription:
                        exercises[currentExercise - 1].exerciseDescription,
                      taskCTA: `Start ${exercises[
                        currentExercise - 1
                      ].exerciseNumber.toLowerCase()}`,
                      taskAction: () => {
                        track("Exercise Started", {
                          exerciseNumber: currentExercise,
                        });
                        navigation.navigate(
                          exercises[currentExercise - 1].route
                        );
                      },
                      taskDuration: "5 MINUTES",
                    }))}
            </VStack>
          </Container>
        </Center>
      </ScrollView>
      <ConfettiCannon
        count={200}
        origin={{ x: -30, y: -20 }}
        autoStart={false}
        ref={confettiCannon}
        explosionSpeed={700}
        fallSpeed={2000}
        fadeOut={true}
      />
    </>
  );
}

export default HomeScreen;
