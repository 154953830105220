
import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  TextArea,
  VStack,
  Checkbox,
  ScrollView,
  KeyboardAvoidingView,
  Image,
} from "native-base";
import { useStore } from "../../state";

function Negative({ navigation, route }) {
  const sensations = useStore((state) => state.logEntries).map(entry => entry.sensation);
  return (
    <ScrollView>
      <KeyboardAvoidingView behavior="padding">
      <Center py="3">
        <Container py="3">
          <VStack space="3">
            <Heading>Negative outcomes that keep you procrastinating</Heading>
            <Text>Procrastination also has negative outcomes. We’ll look at three of them:</Text>
            <Text>• More discomfort</Text>
            <Text>• Backfiring self-criticism</Text>
            <Text>• Mounting pressure</Text>
            <Button
              variant="outline"
              onPress={() => {
                navigation.push("ReinforcementFactors", {
                  screen: "Discomfort",
                });
              }}
            >
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
      </KeyboardAvoidingView>
    </ScrollView>
  );
}

export default Negative;