import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  Checkbox,
  ScrollView,
  Image,
} from "native-base";
import { questions } from "./questions";

function Assess({ navigation, route }) {
  return (
    <ScrollView>
      <Center py="3">
        <Container py="3">
          <VStack space="3">
            <Heading>Assess your readiness for change</Heading>
            <Text>
              We are going to ask you 9 questions to assess your readiness for
              change. We’ll analyze your responses to see what you can do to
              increase your chances of success.
            </Text>
            <Text>
              For the best results answer each question honestly! Don’t just
              tell us what you think we want to hear.
            </Text>
            <Button
              variant="outline"
              onPress={() => {
                navigation.push("Change", {
                  screen: questions[0].title,
                });
              }}
            >
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Assess;
