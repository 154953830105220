import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  Checkbox,
  ScrollView,
  Image,
} from "native-base";
import image from "../../assets/cognitive-triangle-procrastination.jpg"

function Unhelpful({ navigation, route }) {
  return (
    <ScrollView h={800}>
      <Center py="3">
        <Container py="3">
          <VStack space="3">
            <Heading>Unhelpful core beliefs drive your procrastination</Heading>
            <Text>The belief that thieves will be punished is probably helpful - it prevents me from stealing. However, we can also have unhelpful beliefs that prevent us from doing things we actually want to do! For instance, the belief that we won’t be able to do a good job might cause us to procrastinate.</Text>
            <Image source={image} width="300" height="300" alt="Cognitive triangle - procrastination" />
            <Text>The fact that we feel tense and anxious at the prospect of doing certain tasks is a clue that there are some unhelpful beliefs driving that feeling. Today we are going to explore the unhelpful beliefs driving your procrastination. </Text>
            <Button
              variant="outline"
              onPress={() => {
                navigation.push("Beliefs", {
                  screen: "Quiz",
                });
              }}
            >
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Unhelpful;