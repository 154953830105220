import * as React from "react";
import {
  Checkbox,
  Heading,
  Button,
  Center,
  Container,
  VStack,
  Text,
  ScrollView,
} from "native-base";
import { useStore } from "../../state";

function Sensation({ navigation, route }) {
  const moduleCompletions = useStore((state) => state.moduleCompletions);
  const newLogEntry = useStore((state) => state.newLogEntry);
  const setNewLogEntry = useStore((state) => state.setNewLogEntry);
  const [selectedSensations, setSelectedSensations] = React.useState([]);
  const complete = (sensation: string) => {
    setNewLogEntry({ ...newLogEntry, sensation });
    if (moduleCompletions.find((m) => m.number === 5)) {
      navigation.push("Reflect", { screen: "Modify" });
      return;
    }
    if (moduleCompletions.find((m) => m.number === 3)) {
      navigation.push("Reflect", { screen: "PositiveOutcome" });
      return;
    }
    navigation.push("Reflect", { screen: "Excuse" });
  };
  return (
    <ScrollView h={800}>
      <Center>
        <Container py="3">
          <VStack space="3">
            <Heading size="lg" mt="3">
              What sensations do you feel when you think about doing the task?
            </Heading>
            <Text>Select from the common sensations or add your own.</Text>
            <>
              <Checkbox.Group
                defaultValue={[]}
                accessibilityLabel="pick sensations"
                onChange={(values) => {
                  setSelectedSensations(values || []);
                }}
              >
                {[
                  "Overwhelmed",
                  "Stressed or anxious",
                  "Bored or uninterested",
                  "Lethargic",
                  "Dread",
                  "Confused",
                  "Excited",
                  "Insecure or inadequate",
                  "Fearful",
                  "Guilt or shame",
                  "Hopeful",
                  "Tense",
                  "Confident",
                ].map((item) => (
                  <Checkbox key={`sensation-${item}`} value={item}>
                    {item}
                  </Checkbox>
                ))}
              </Checkbox.Group>
              {selectedSensations.length > 0 ? (
                <Button onPress={() => complete(selectedSensations.join(", "))}>
                  Continue
                </Button>
              ) : (
                <Button variant="outline" onPress={() => complete("Nothing")}>
                  Nothing
                </Button>
              )}
            </>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Sensation;
