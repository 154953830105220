import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  TextArea,
  VStack,
  Checkbox,
  ScrollView,
  Image,
  KeyboardAvoidingView,
  Box
} from "native-base";
import { useStore } from "../../state";
import { useColorScheme } from "react-native";

function WhatTasks({ navigation, route }) {
  const logEntries = useStore((state) => state.logEntries);
  const tasks = logEntries.map((entry) => entry.task);
  const uniqueTasks = [...new Set(tasks)];
  const insight = useStore((state) => state.insight);
  const setInsight = useStore((state) => state.setInsight);
  const scheme = useColorScheme()
  return (
    <ScrollView h={800}>
      <KeyboardAvoidingView behavior="padding">
        <Center py="3">
          <Container py="3">
            <VStack space="3">
              <Heading>Tasks you procrastinated</Heading>
              <Text>
                The fact that you procrastinated these tasks means they all
                cause you discomfort. What is it about them that made you feel
                uncomfortable?
              </Text>
              <Box
                borderWidth="1"
                borderColor={
                  scheme === "dark" ? "coolGray.600" : "coolGray.300"
                }
                backgroundColor={
                  scheme === "dark" ? "coolGray.600" : "coolGray.300"
                }
                p="5"
                rounded="8"
              >
                {uniqueTasks.map((task) => (
                  <Text key={task}>
                    {task}
                  </Text>
                ))}
              </Box>
              <Text>
                This is an opportunity to learn something about yourself!
              </Text>
              <Text>What patterns do you notice?</Text>
              <TextArea
                placeholder="Capture your first insight..."
                autoCompleteType="text"
                value={insight}
                onChangeText={setInsight}
              />
              <Button
                variant="outline"
                onPress={() => {
                  navigation.push("Beliefs", {
                    screen: "Insights",
                  });
                }}
              >
                Continue
              </Button>
            </VStack>
          </Container>
        </Center>
      </KeyboardAvoidingView>
    </ScrollView>
  );
}

export default WhatTasks;