import * as React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import Welcome from "./1Welcome";
import Tasks from "./2Tasks";
import Insights from "./3Insights";
import Discomfort from "./4Discomfort";
import CoreBeliefs from "./5CoreBeliefs";
import Unhelpful from "./6Unhelpful";
import Quiz from "./7Quiz";
import UnhelpfulBeliefs from "./8UnhelpfulBeliefs";
import Why from "./9Why";
import Notice from "./10Notice";
import Mission from "./11Mission";
import FearOfUncertainty from "./CoreBeliefs/FearOfUncertainty";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  ScrollView,
} from "native-base";
import { questions } from './questions'
import { useStore } from "../../state"
import FearOfJudgement from "./CoreBeliefs/FearOfJudgement";
import IntoleranceOfLowEnergy from "./CoreBeliefs/IntoleranceOfLowEnergy";
import LackOfSelfConfidence from "./CoreBeliefs/LackOfSelfConfidence";
import NeedForControl from "./CoreBeliefs/NeedForControl";
import NeedForPleasure from "./CoreBeliefs/NeedForPleasure";

const Stack = createNativeStackNavigator();

const Question = ({ navigation, route}) => {
  const responses = useStore(state => state.quizResponses)
  const setResponses = useStore(state => state.setQuizResponses)
  const setCoreBelief = useStore(state => state.setCoreBeliefs)
  const undoResponse = useStore(state => state.undoQuizResponse)
  const { question, index } = route.params;
  const submit = (score: number) => {
    setResponses({ belief: question.category, score });
    if (index === questions.length - 1) {
      setCoreBelief(responses);
      navigation.push("Beliefs", {
        screen: "UnhelpfulBeliefs",
      });
    } else {
      navigation.push("Beliefs", {
        screen: questions[index + 1].title,
      });
    }
  };

  React.useEffect(
    () =>
      navigation.addListener('beforeRemove', () => {
        undoResponse()
      }),
    [navigation]
  );

  return (
    <>
      <ScrollView>
        <Center>
          <Container py="3" w="full">
            <VStack space="3" w="full">
              <Heading mt="3">{question.title}</Heading>
              <Text>How much is this like you?</Text>
              <Button onPress={() => submit(4)}>Very much like me</Button>
              <Button onPress={() => submit(3)}>Somewhat like me</Button>
              <Button onPress={() => submit(2)}>Neutral</Button>
              <Button onPress={() => submit(1)}>Not much like me</Button>
              <Button onPress={() => submit(0)}>Not at all like me</Button>
              <Text fontSize="14">
                Question {index + 1} of {questions.length}
              </Text>
            </VStack>
          </Container>
        </Center>
      </ScrollView>
    </>
  );
}

function Beliefs() {
  return (
    <Stack.Navigator
      initialRouteName="Welcome"
      screenOptions={{
        headerShown: false,
        title: "Exercise Two",
      }}
    >
      <Stack.Screen name="Welcome" component={Welcome} />
      <Stack.Screen name="Tasks" component={Tasks} />
      <Stack.Screen name="Insights" component={Insights} />
      <Stack.Screen name="Discomfort" component={Discomfort} />
      <Stack.Screen name="CoreBeliefs" component={CoreBeliefs} />
      <Stack.Screen name="Unhelpful" component={Unhelpful} />
      <Stack.Screen name="Quiz" component={Quiz} />
      {questions.map((question, index) => (
        <Stack.Screen
          key={question.title}
          name={question.title}
          component={Question}
          initialParams={{ question, index}}
        />
      ))}
      <Stack.Screen name="UnhelpfulBeliefs" component={UnhelpfulBeliefs} />
      <Stack.Screen name="FearOfUncertainty" component={FearOfUncertainty} />
      <Stack.Screen name="FearOfJudgement" component={FearOfJudgement} />
      <Stack.Screen name="IntoleranceOfLowEnergy" component={IntoleranceOfLowEnergy} />
      <Stack.Screen name="LackOfSelfConfidence" component={LackOfSelfConfidence} />
      <Stack.Screen name="NeedForControl" component={NeedForControl} />
      <Stack.Screen name="NeedForPleasure" component={NeedForPleasure} />
      <Stack.Screen name="Why" component={Why} />
      <Stack.Screen name="Notice" component={Notice} />
      <Stack.Screen name="Mission" component={Mission} />
    </Stack.Navigator>
  );
}

export default Beliefs;