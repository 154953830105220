import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  ScrollView,
  Image,
} from "native-base";

function FearOfJudgement({ navigation, route }) {
  return (
    <ScrollView>
      <Center py="3">
        <Container py="3">
          <VStack space="3">
            <Heading>Fear of judgement</Heading>
            <Text>Some people put a lot of pressure on themselves to do things to a very high standard. They demand perfection of themselves. The problem with these high expectations of themselves is that they fear they will fall short. They fear that they will fail or someone will disapprove of them.</Text>
            <Text>These people tell themselves things like "I must do things perfectly”, or “I can’t have others think poorly of me”. They assume things like “if I try, then I will only fail”, or “if I put my work out there, then others will think badly of me”.</Text>
            <Text>They struggle with tasks where they might be evaluated. Tasks such as exams, art work, socialising or making a lifestyle change can be a struggle. They predict they will fail or others will negatively judge them. This makes them feel anxious, fearful or embarrassed.</Text>
            <Text>For these people procrastination acts as a form of self protection. By procrastinating they avoid the risk of failure or disapproval.</Text>
            <Button
              variant="outline"
              onPress={() => { navigation.goBack(); }}
            >
              Back
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default FearOfJudgement;