import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  ScrollView,
  Box,
  Flex,
  Progress,
  Pressable
} from "native-base";
import { useColorScheme } from 'react-native';
import { useStore } from "../../state";
import { MaterialCommunityIcons } from '@expo/vector-icons'; 

function computeSeverity(score: number) {
  if (score > 11) {
    return { color: "danger", label: "Strong driver" }
  }
  if (score > 4) {
    return { color: "warning", label: "Moderate driver" }
  }

    return { color: "yellow", label: "Weak driver" }
}

function UnhelpfulBeliefs({ navigation, route }) {
  const coreBeliefs = useStore((state) => state.coreBeliefs);
  const undoResponse = useStore(state => state.undoQuizResponse)
  const scheme = useColorScheme();

  React.useEffect(
    () =>
      navigation.addListener('beforeRemove', () => {
        undoResponse()
      }),
    [navigation]
  );

  const renderBelief = (route, title, score, last?) => (
    <Pressable onPress={() => navigation.push("Beliefs", { screen: route })}>
        <Flex justifyContent="space-between" flexDir="row" alignItems="center" mt="3">
          <Text mr="3">{title}</Text>
          <Progress
            value={(score / 20) * 100}
            maxW="40%"
            flex="1"
            marginLeft="auto"
            colorScheme={computeSeverity(score).color}
          />
          <MaterialCommunityIcons name="chevron-right" size={32} color={scheme === "dark" ? "white" : "black"} />
        </Flex>
        <Box borderBottomWidth={last ? "0" : "2"} borderBottomColor="gray.600" pb="6">
          <Text fontSize="12">{computeSeverity(score).label}</Text>
        </Box>
    </Pressable>
  );

  return (
    <ScrollView h={800}>
      <Center py="3">
        <Container py="3">
          <VStack space="3">
            <Heading>Unhelpful beliefs driving your procrastination</Heading>
            <Text>
              Select a core belief to learn more about how it is impacting your
              procrastination.
            </Text>
            {renderBelief("NeedForControl", "Need for control", coreBeliefs.needToBeInCharge)}
            {renderBelief("NeedForPleasure", "Pleasure seeking", coreBeliefs.pleasureSeeking)}
            {renderBelief("FearOfJudgement", "Fear of judgement", coreBeliefs.fearOfFailure)}
            {renderBelief("FearOfUncertainty", "Fear of uncertainty", coreBeliefs.fearOfUncertainty)}
            {renderBelief("LackOfSelfConfidence", "Low self confidence", coreBeliefs.lowSelfConfidence)}
            {renderBelief("IntoleranceOfLowEnergy", "Depleted energy", coreBeliefs.depletedEnergy, true)}
            <Button
              variant="outline"
              onPress={() => {
                navigation.push("Beliefs", {
                  screen: "Why",
                });
              }}
            >
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default UnhelpfulBeliefs;