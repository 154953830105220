import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  TextArea,
  VStack,
  Checkbox,
  ScrollView,
  KeyboardAvoidingView,
  Image,
} from "native-base";
import { useStore } from "../../state";
import { track } from "@amplitude/analytics-react-native";

function Mission({ navigation, route }) {
  const sensations = useStore((state) => state.logEntries).map(entry => entry.sensation);
  const completeModule = useStore((state) => state.completeModule);
  return (
    <ScrollView>
      <KeyboardAvoidingView behavior="padding">
        <Center py="3">
          <Container py="3">
            <VStack space="3">
              <Heading>This week's exercise</Heading>
              <Text>
                This week you’ll take notice of the final piece in the
                procrastination puzzle: reinforcement factors!
              </Text>
              <Text>
                This will give you a complete model of your own procrastination.
                You'll have all the pieces you need to learn the skill of overcoming procrastination!
              </Text>
              <Button
                onPress={() => {
                  completeModule(3);
                  track("Exercise Completed", { exerciseNumber: 3 });
                  navigation.reset({
                    index: 1,
                    routes: [
                      { name: "Journey" },
                      {
                        name: "Home",
                        params: { fireConfettiCannon: true, exerciseNumber: 3 },
                      },
                    ],
                  });
                }}
              >
                Finish
              </Button>
            </VStack>
          </Container>
        </Center>
      </KeyboardAvoidingView>
    </ScrollView>
  );
}

export default Mission;