import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  Checkbox,
  ScrollView,
  Image,
} from "native-base";
import image from "../../assets/cycle.jpg";

function Welcome({ navigation, route }) {
  return (
    <ScrollView>
      <Center py="3">
        <Container py="3">
          <VStack space="3">
            <Heading>Welcome to module two 👋</Heading>
            <Text>
              For 7 days you have brought your awareness to your procrastination
              reinforcement loop. This is the automatic response that we use to
              avoid discomfort. 👇
            </Text>
            <Image source={image} height={320} width={320} alt="Procrastination reinforcement loop" />
            <Text>
              Before we move on, let's draw out insights from your reflections.
            </Text>
            <Button
              variant="outline"
              onPress={() => {
                navigation.push("Beliefs", {
                  screen: "Tasks",
                });
              }}
            >
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Welcome;