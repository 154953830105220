import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  TextArea,
  VStack,
  Checkbox,
  ScrollView,
  KeyboardAvoidingView,
  Image,
} from "native-base";
import { useStore } from "../../state";

function Unchallenged({ navigation, route }) {
  const sensations = useStore((state) => state.logEntries).map(entry => entry.sensation);
  return (
    <ScrollView>
      <KeyboardAvoidingView behavior="padding">
      <Center py="3">
        <Container py="3">
          <VStack space="3">
            <Heading>Leaving core beliefs unchallenged</Heading>
            <Text>In the previous exercise, you learnt about the core beliefs that make you feel uneasy about doing certain tasks. Because you’ve held those beliefs for a long time you feel better when you stick to them.</Text>
            <Text>For instance, take the need for control. Procrastination gives a sense of power and control because you're doing things on your own terms</Text>
            <Button
              variant="outline"
              onPress={() => {
                navigation.push("ReinforcementFactors", {
                  screen: "Negative",
                });
              }}
            >
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
      </KeyboardAvoidingView>
    </ScrollView>
  );
}

export default Unchallenged;