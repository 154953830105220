/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNotificationDevice = /* GraphQL */ `
  mutation CreateNotificationDevice(
    $input: CreateNotificationDeviceInput!
    $condition: ModelNotificationDeviceConditionInput
  ) {
    createNotificationDevice(input: $input, condition: $condition) {
      id
      token
      timezone
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateNotificationDevice = /* GraphQL */ `
  mutation UpdateNotificationDevice(
    $input: UpdateNotificationDeviceInput!
    $condition: ModelNotificationDeviceConditionInput
  ) {
    updateNotificationDevice(input: $input, condition: $condition) {
      id
      token
      timezone
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteNotificationDevice = /* GraphQL */ `
  mutation DeleteNotificationDevice(
    $input: DeleteNotificationDeviceInput!
    $condition: ModelNotificationDeviceConditionInput
  ) {
    deleteNotificationDevice(input: $input, condition: $condition) {
      id
      token
      timezone
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createAccountDeletion = /* GraphQL */ `
  mutation CreateAccountDeletion(
    $input: CreateAccountDeletionInput!
    $condition: ModelAccountDeletionConditionInput
  ) {
    createAccountDeletion(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateAccountDeletion = /* GraphQL */ `
  mutation UpdateAccountDeletion(
    $input: UpdateAccountDeletionInput!
    $condition: ModelAccountDeletionConditionInput
  ) {
    updateAccountDeletion(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteAccountDeletion = /* GraphQL */ `
  mutation DeleteAccountDeletion(
    $input: DeleteAccountDeletionInput!
    $condition: ModelAccountDeletionConditionInput
  ) {
    deleteAccountDeletion(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createFeedback = /* GraphQL */ `
  mutation CreateFeedback(
    $input: CreateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    createFeedback(input: $input, condition: $condition) {
      id
      rating
      liked
      improvements
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateFeedback = /* GraphQL */ `
  mutation UpdateFeedback(
    $input: UpdateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    updateFeedback(input: $input, condition: $condition) {
      id
      rating
      liked
      improvements
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteFeedback = /* GraphQL */ `
  mutation DeleteFeedback(
    $input: DeleteFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    deleteFeedback(input: $input, condition: $condition) {
      id
      rating
      liked
      improvements
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createModuleCompletion = /* GraphQL */ `
  mutation CreateModuleCompletion(
    $input: CreateModuleCompletionInput!
    $condition: ModelModuleCompletionConditionInput
  ) {
    createModuleCompletion(input: $input, condition: $condition) {
      id
      number
      insight
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateModuleCompletion = /* GraphQL */ `
  mutation UpdateModuleCompletion(
    $input: UpdateModuleCompletionInput!
    $condition: ModelModuleCompletionConditionInput
  ) {
    updateModuleCompletion(input: $input, condition: $condition) {
      id
      number
      insight
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteModuleCompletion = /* GraphQL */ `
  mutation DeleteModuleCompletion(
    $input: DeleteModuleCompletionInput!
    $condition: ModelModuleCompletionConditionInput
  ) {
    deleteModuleCompletion(input: $input, condition: $condition) {
      id
      number
      insight
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createLogEntry = /* GraphQL */ `
  mutation CreateLogEntry(
    $input: CreateLogEntryInput!
    $condition: ModelLogEntryConditionInput
  ) {
    createLogEntry(input: $input, condition: $condition) {
      id
      task
      excuse
      sensation
      reflection
      belief
      positiveOutcome
      negativeOutcome
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateLogEntry = /* GraphQL */ `
  mutation UpdateLogEntry(
    $input: UpdateLogEntryInput!
    $condition: ModelLogEntryConditionInput
  ) {
    updateLogEntry(input: $input, condition: $condition) {
      id
      task
      excuse
      sensation
      reflection
      belief
      positiveOutcome
      negativeOutcome
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteLogEntry = /* GraphQL */ `
  mutation DeleteLogEntry(
    $input: DeleteLogEntryInput!
    $condition: ModelLogEntryConditionInput
  ) {
    deleteLogEntry(input: $input, condition: $condition) {
      id
      task
      excuse
      sensation
      reflection
      belief
      positiveOutcome
      negativeOutcome
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCoreBeliefs = /* GraphQL */ `
  mutation CreateCoreBeliefs(
    $input: CreateCoreBeliefsInput!
    $condition: ModelCoreBeliefsConditionInput
  ) {
    createCoreBeliefs(input: $input, condition: $condition) {
      id
      needToBeInCharge
      pleasureSeeking
      fearOfFailure
      fearOfUncertainty
      lowSelfConfidence
      depletedEnergy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateCoreBeliefs = /* GraphQL */ `
  mutation UpdateCoreBeliefs(
    $input: UpdateCoreBeliefsInput!
    $condition: ModelCoreBeliefsConditionInput
  ) {
    updateCoreBeliefs(input: $input, condition: $condition) {
      id
      needToBeInCharge
      pleasureSeeking
      fearOfFailure
      fearOfUncertainty
      lowSelfConfidence
      depletedEnergy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteCoreBeliefs = /* GraphQL */ `
  mutation DeleteCoreBeliefs(
    $input: DeleteCoreBeliefsInput!
    $condition: ModelCoreBeliefsConditionInput
  ) {
    deleteCoreBeliefs(input: $input, condition: $condition) {
      id
      needToBeInCharge
      pleasureSeeking
      fearOfFailure
      fearOfUncertainty
      lowSelfConfidence
      depletedEnergy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
