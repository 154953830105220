import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useStore } from "../state";
import * as React from "react";
import { Image, Heading, Button, Center, Container, Text, VStack, ScrollView, HStack, Badge, Box, Pressable, Spacer, Flex, Progress } from "native-base";
import image from "../assets/insights.png"
import tasksImage from "../assets/visionary.png"
import coreBeliefsImage from "../assets/thought.png"
import { MaterialCommunityIcons } from '@expo/vector-icons'; 
import { FontAwesome5 } from '@expo/vector-icons';
import { useColorScheme } from 'react-native';
import { track } from "@amplitude/analytics-react-native";
import FearOfJudgement from "./Beliefs/CoreBeliefs/FearOfJudgement";
import FearOfUncertainty from "./Beliefs/CoreBeliefs/FearOfUncertainty";
import IntoleranceOfLowEnergy from "./Beliefs/CoreBeliefs/IntoleranceOfLowEnergy";
import LackOfSelfConfidence from "./Beliefs/CoreBeliefs/LackOfSelfConfidence";
import NeedForControl from "./Beliefs/CoreBeliefs/NeedForControl";
import NeedForPleasure from "./Beliefs/CoreBeliefs/NeedForPleasure";

const Stack = createNativeStackNavigator();

function InsightsIndex({ navigation, route }) {
    const scheme = useColorScheme();
    const moduleCompletions = useStore(state => state.moduleCompletions)

    React.useEffect(() => {
        track("Insights Page Viewed")
    }, []);
  return (
    <ScrollView>
      <Center py="6">
        <Image
          source={image}
          width="170"
          height="240"
          alt="Procrastination App"
        />
        <Container py="3" w="full">
          <Heading my="3">Your procrastination cycle</Heading>
          <Text mb="6">This is the cycle driving your procrastination.</Text>

          <VStack space="3" w="full">
            <Pressable
              onPress={() =>
                navigation.navigate("Insights", { screen: "Tasks" })
              }
            >
              <Box borderWidth="1" borderColor="coolGray.300" p="5" rounded="8">
                <HStack alignItems="center">
                  <VStack>
                    <Text fontWeight="bold" fontSize="lg">
                      Tasks
                    </Text>
                    <Text maxW="90%">
                      Certain tasks or goals trigger your procrastination cycle.
                    </Text>
                  </VStack>
                  <Spacer />
                  <MaterialCommunityIcons
                    name="chevron-right"
                    size={32}
                    color={scheme === "dark" ? "white" : "black"}
                  />
                </HStack>
              </Box>
            </Pressable>

            <Box alignSelf="center">
              <FontAwesome5
                name="long-arrow-alt-down"
                size={24}
                color={scheme === "dark" ? "white" : "black"}
              />
            </Box>

            <Pressable
              onPress={() =>
                navigation.navigate("Insights", { screen: "CoreBeliefs" })
              }
            >
              <Box borderWidth="1" borderColor="coolGray.300" p="5" rounded="8">
                <HStack alignItems="center">
                  <VStack>
                    <Text fontWeight="bold" fontSize="lg">
                      Unhelpful core beliefs
                    </Text>
                    <Text maxW="90%">
                      Those tasks activate your unhelpful core beliefs.
                    </Text>
                  </VStack>
                  <Spacer />
                  <MaterialCommunityIcons
                    name="chevron-right"
                    size={32}
                    color={scheme === "dark" ? "white" : "black"}
                  />
                </HStack>
              </Box>
            </Pressable>


            <Box alignSelf="center">
              <FontAwesome5
                name="long-arrow-alt-down"
                size={24}
                color={scheme === "dark" ? "white" : "black"}
              />
            </Box>
            <Box borderWidth="1" borderColor="coolGray.300" p="5" rounded="8">
              <HStack alignItems="center">
                <VStack>
                  <Text fontWeight="bold" fontSize="lg">
                    Uncomfortable sensations
                  </Text>
                  <Text maxW="90%">
                    Core beliefs make the task feel unappealing and create an
                    urge to avoid those feelings.
                  </Text>
                </VStack>
                <Spacer />
              </HStack>
            </Box>

            <Box alignSelf="center">
              <FontAwesome5
                name="long-arrow-alt-down"
                size={24}
                color={scheme === "dark" ? "white" : "black"}
              />
            </Box>
            <Box borderWidth="1" borderColor="coolGray.300" p="5" rounded="8">
              <HStack alignItems="center">
                <VStack>
                  <Text fontWeight="bold" fontSize="lg">
                    Rationalizations
                  </Text>
                  <Text maxW="90%">
                    To feel better about putting off the task, you make excuses
                    to justify procrastination.
                  </Text>
                </VStack>
                <Spacer />
              </HStack>
            </Box>

            <Box alignSelf="center">
              <FontAwesome5
                name="long-arrow-alt-down"
                size={24}
                color={scheme === "dark" ? "white" : "black"}
              />
            </Box>
            <Box borderWidth="1" borderColor="coolGray.300" p="5" rounded="8">
              <HStack alignItems="center">
                <VStack>
                  <Text fontWeight="bold" fontSize="lg">
                    Procrastination activities
                  </Text>
                  <Text maxW="90%">
                    You turn to other activities to divert your attention.
                  </Text>
                </VStack>
                <Spacer />
              </HStack>
            </Box>

            <Box alignSelf="center">
              <FontAwesome5
                name="long-arrow-alt-down"
                size={24}
                color={scheme === "dark" ? "white" : "black"}
              />
            </Box>
            <Box borderWidth="1" borderColor="coolGray.300" p="5" rounded="8">
              <HStack alignItems="center">
                <VStack>
                  <Text fontWeight="bold" fontSize="lg">
                    Reinforcement factors
                  </Text>
                  <Text maxW="90%">
                    Procrastination relieves your initial discomfort and creates
                    consequences that keep the cycle going.
                  </Text>
                </VStack>
                <Spacer />
              </HStack>
            </Box>
          </VStack>

          <Heading mt="9" mb="3">
            Your insights
          </Heading>
          <Text mb="6">
            Completing lessons will reveal new insights about your
            procrastination.
          </Text>
          <VStack space="6">
            {moduleCompletions.map((m) =>
              m.insight ? (
                <Box
                  key={m.completedAt}
                  borderWidth="1"
                  borderColor={
                    scheme === "dark" ? "coolGray.600" : "coolGray.300"
                  }
                  backgroundColor={
                    scheme === "dark" ? "coolGray.600" : "coolGray.300"
                  }
                  p="5"
                  rounded="8"
                >
                  <Text>{m.insight}</Text>
                </Box>
              ) : null
            )}
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

function Tasks({ navigation, route }) {
  return (
    <Center>
      <Image
        mt="6"
        source={tasksImage}
        width="230"
        height="200"
        alt="Procrastination App"
      />
      <Container py="3" w="full">
        <Heading my="3">Tasks</Heading>
        <Text mb="6">
          Keep adding daily reflections to view new insights about the tasks you
          procrastinate.
        </Text>
      </Container>
    </Center>
  );
}

function computeSeverity(score: number) {
  if (score > 11) {
    return { color: "danger", label: "Strong driver" }
  }
  if (score > 4) {
    return { color: "warning", label: "Moderate driver" }
  }

    return { color: "yellow", label: "Weak driver" }
}

function CoreBeliefs({ navigation, route }) {
    const scheme = useColorScheme();
  const coreBeliefs = useStore(state => state.coreBeliefs)
  const renderBelief = (route, title, score, last?) => (
    <Pressable onPress={() => navigation.navigate("Insights", { screen: route })}>
        <Flex justifyContent="space-between" flexDir="row" alignItems="center" mt="3">
          <Text mr="3">{title}</Text>
          <Progress
            value={(score / 20) * 100}
            maxW="40%"
            flex="1"
            marginLeft="auto"
            colorScheme={computeSeverity(score).color}
          />
          <MaterialCommunityIcons name="chevron-right" size={32} color={scheme === "dark" ? "white" : "black"} />
        </Flex>
        <Box borderBottomWidth={last ? "0" : "2"} borderBottomColor="gray.600" pb="6">
          <Text fontSize="12">{computeSeverity(score).label}</Text>
        </Box>
    </Pressable>
  );
  return (
    <>
      {coreBeliefs ? (
        <ScrollView h={800}>
          <Center py="3">
            <Container py="3">
              <VStack space="3">
                <Heading>
                  Unhelpful beliefs driving your procrastination
                </Heading>
                <Text>
                  Select a core belief to learn more about how it is impacting
                  your procrastination.
                </Text>
                {renderBelief(
                  "NeedForControl",
                  "Need for control",
                  coreBeliefs.needToBeInCharge
                )}
                {renderBelief(
                  "NeedForPleasure",
                  "Pleasure seeking",
                  coreBeliefs.pleasureSeeking
                )}
                {renderBelief(
                  "FearOfJudgement",
                  "Fear of judgement",
                  coreBeliefs.fearOfFailure
                )}
                {renderBelief(
                  "FearOfUncertainty",
                  "Fear of uncertainty",
                  coreBeliefs.fearOfUncertainty
                )}
                {renderBelief(
                  "LackOfSelfConfidence",
                  "Low self confidence",
                  coreBeliefs.lowSelfConfidence
                )}
                {renderBelief(
                  "IntoleranceOfLowEnergy",
                  "Depleted energy",
                  coreBeliefs.depletedEnergy,
                  true
                )}
              </VStack>
            </Container>
          </Center>
        </ScrollView>
      ) : (
        <Center>
          <Image
            mt="6"
            source={coreBeliefsImage}
            width="230"
            height="260"
            alt="Procrastination App"
          />
          <Container py="3" w="full">
            <Heading my="3">Core beliefs</Heading>
            <Text mb="6">
              Complete lesson two to view new insights about your core beliefs.
            </Text>
          </Container>
        </Center>
      )}
    </>
  );
}

function Insights({ navigation, route }) {
  return (
    <Stack.Navigator initialRouteName="InsightsIndex">
      <Stack.Screen
        name="InsightsIndex"
        component={InsightsIndex}
        options={{ title: "Procrastination App" }}
      />
      <Stack.Screen
        name="Tasks"
        component={Tasks}
        options={{ title: "Tasks" }}
      />
      <Stack.Screen
        name="CoreBeliefs"
        component={CoreBeliefs}
        options={{ title: "Core Beliefs" }}
      />
      <Stack.Screen
        name="FearOfUncertainty"
        component={FearOfUncertainty}
        options={{ title: "Core Beliefs" }}
      />
      <Stack.Screen
        name="FearOfJudgement"
        component={FearOfJudgement}
        options={{ title: "Core Beliefs" }}
      />
      <Stack.Screen
        name="IntoleranceOfLowEnergy"
        component={IntoleranceOfLowEnergy}
        options={{ title: "Core Beliefs" }}
      />
      <Stack.Screen
        name="LackOfSelfConfidence"
        component={LackOfSelfConfidence}
        options={{ title: "Core Beliefs" }}
      />
      <Stack.Screen
        name="NeedForControl"
        component={NeedForControl}
        options={{ title: "Core Beliefs" }}
      />
      <Stack.Screen
        name="NeedForPleasure"
        component={NeedForPleasure}
        options={{ title: "Core Beliefs" }}
      />
    </Stack.Navigator>
  );
}

export default Insights;