import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  TextArea,
  VStack,
  Checkbox,
  ScrollView,
  KeyboardAvoidingView,
  Image,
  Box,
} from "native-base";
import { useStore } from "../../state";
import { useColorScheme } from "react-native";

function Pleasure({ navigation, route }) {
  const distractions = useStore(state => state.distractions)
  const scheme = useColorScheme()
  return (
    <ScrollView>
      <KeyboardAvoidingView behavior="padding">
        <Center py="3">
          <Container py="3">
            <VStack space="3">
              <Heading>Experiencing pleasure </Heading>
              <Text>
                Generally the activities we do in place of important tasks are
                pleasurable. Our brain loves pleasure!
              </Text>
              {distractions.length ? (
                <>
                  <Text>
                    These are the activities you said you do instead of the task
                    at hand. Chances are you enjoy them more than whatever you
                    were procrastinating.
                  </Text>
                  {distractions.map((distraction, index) => (
                    <Box
                      key={`${index}-${distraction}`}
                      borderWidth="1"
                      borderColor={
                        scheme === "dark" ? "coolGray.600" : "coolGray.300"
                      }
                      backgroundColor={
                        scheme === "dark" ? "coolGray.600" : "coolGray.300"
                      }
                      p="5"
                      rounded="8"
                    >
                      <Text>{distraction}</Text>
                    </Box>
                  ))}
                </>
              ) : (
                <Text>
                  Typical procrastination activities such as scrolling social
                  media, watching netflix and gaming are all pretty
                  entertaining!
                </Text>
              )}
              <Text>
                The pleasure you get from these activities can motivate you to
                keep procrastinating.
              </Text>
              <Button
                variant="outline"
                onPress={() => {
                  navigation.push("ReinforcementFactors", {
                    screen: "Unchallenged",
                  });
                }}
              >
                Continue
              </Button>
            </VStack>
          </Container>
        </Center>
      </KeyboardAvoidingView>
    </ScrollView>
  );
}

export default Pleasure;