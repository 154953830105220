import * as React from "react";
import { API, Auth, graphqlOperation } from "aws-amplify";
import {
  Stack,
  Radio,
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  TextArea,
} from "native-base";
import { createAccountDeletion } from "../graphql/mutations";
import { track } from "@amplitude/analytics-react-native";

function DeleteAccount({ navigation, route }) {
  const [deleted, setDeleted] = React.useState(false)
  const complete = async () => {
    const record = await API.graphql({
      query: createAccountDeletion,
      variables: { input: {} },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    if (record) {
        setDeleted(true);
        track("Account Deletion Requested")
    }
  };
  return (
    <Center>
      <Container py="3">
        <VStack space="3">
          {deleted ? (
            <>
              <Heading size="lg" mt="3">
                Account Deleted
              </Heading>
              <Text>We're sorry to see you go!</Text>
              <Text>Your account will be deleted within 48 hours.</Text>
              <Button variant="outline" onPress={() => Auth.signOut()}>
                Sign out
              </Button>
            </>
          ) : (
            <>
              <Heading size="lg" mt="3">
                Delete Account
              </Heading>
              <Text>
                Deleting your account will remove all data associated with your
                account.
              </Text>
              <Text>Are you sure you want to delete your account?</Text>
              <Button mt="4" onPress={() => complete()} colorScheme="secondary">
                Delete account
              </Button>
              <Button
                variant="outline"
                onPress={() =>
                  navigation.reset({
                    index: 1,
                    routes: [{ name: "Journey" }, { name: "Home" }],
                  })
                }
              >
                Cancel
              </Button>
            </>
          )}
        </VStack>
      </Container>
    </Center>
  );
}

export default DeleteAccount;
