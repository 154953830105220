import * as React from "react";
import {
  Checkbox,
  Heading,
  Button,
  Center,
  Container,
  VStack,
  Text,
  ScrollView,
} from "native-base";
import { useStore } from "../../state";
import { coreBeliefStatement } from "../../constants";

function Beliefs({ navigation, route }) {
  const newLogEntry = useStore((state) => state.newLogEntry);
  const setNewLogEntry = useStore((state) => state.setNewLogEntry);
  const complete = (belief: string) => {
    setNewLogEntry({ ...newLogEntry, belief });
    navigation.push("Reflect", { screen: "Sensation" });
  };
  return (
    <ScrollView>
      <Center>
        <Container py="3">
          <VStack space="3">
            <Heading size="lg" mt="3">
              What unhelpful belief is impacting your procrastination?
            </Heading>
            <Text>
              Which of these unhelpful beliefs is leading you to put off the
              task you selected?
            </Text>
            {[
              "Need for control",
              "Need for pleasure",
              "Fear of judgement",
              "Fear of uncertainty",
              "Lack of self-confidence",
              "Intolerance of low energy",
            ].map((item) => (
              <Button key={item} onPress={() => complete(item)}>
                {`${item}: ${coreBeliefStatement[item]}`}
              </Button>
            ))}
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Beliefs;
