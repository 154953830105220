import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  Checkbox,
  ScrollView,
  Image,
} from "native-base";

function FourPoints({ navigation, route }) {
  return (
    <ScrollView>
      <Center py="3">
        <Container py="3">
          <VStack space="3">
            <Heading>
              The four points of the procrastination cycle to intervene
            </Heading>
            <Text>
              There are four main strategies for breaking the procrastination
              cycle:
            </Text>
            <Text>1) Modify tasks or your environment</Text>
            <Text>
              2) Learn to dismiss procrastination excuses (ie. rationalizations)
            </Text>
            <Text>3) Adjust unhelpful core beliefs</Text>
            <Text>4) Learn skills to tolerate discomfort</Text>
            <Text>We are going to teach you all four skills!</Text>
            <Button
              variant="outline"
              onPress={() => {
                navigation.push("Change", {
                  screen: "Increasing",
                });
              }}
            >
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default FourPoints;
