import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  ScrollView,
  Checkbox,
} from "native-base";
import { useStore } from "../../state";

function IdentifyBroad({ navigation, route }) {
  const setTasks = useStore((state) => state.setTasks);
  return (
    <ScrollView>
      <Center>
        <Container py="3" w="full">
          <VStack space="3" w="full">
            <Heading mt="3">What do you procrastinate?</Heading>
            <Text>Select the tasks or goals you tend to procrastinate:</Text>
            <Checkbox.Group
              defaultValue={[]}
              accessibilityLabel="pick tasks"
              onChange={(values) => {
                setTasks(values || []);
              }}
            >
                {[
                  "Choosing between options",
                  "Making plans",
                  "Asking questions or requesting help",
                  "Making phone calls",
                  "Being assertive with colleagues",
                  "Responding to emails",
                  "Job applications",
                  "Research or reading",
                  "Daily chores (dishes, tidying, cooking, etc.)",
                  "Household projects",
                  "Grocery shopping",
                  "Running errands",
                  "Homework or assignments",
                  "Studying for exams",
                  "Attending medical appointments",
                  "Changing diet",
                  "Starting an exercise routine",
                  "Quitting smoking/alcohol/drugs",
                  "Opening mail",
                  "Paying bills",
                  "Budgeting",
                  "Tax return",
                  "Spending time with others",
                  "Discussing problems",
                  "Starting a hobby",
                  "Getting involved in something spiritual",
                  "Committing to something new",
                ].map((item) => (
                  <Checkbox key={item} value={item}>
                    {item}
                  </Checkbox>
                ))}
            </Checkbox.Group>
            <Button
              variant="outline"
              onPress={() => {
                navigation.push("UnderstandingProcrastination", {
                  screen: "Activities",
                });
              }}
            >
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default IdentifyBroad;
