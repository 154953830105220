import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  ScrollView,
} from "native-base";

function NeedForControl({ navigation, route }) {
  return (
    <ScrollView>
      <Center py="3">
        <Container py="3">
          <VStack space="3">
            <Heading>Need for control</Heading>
            <Text>People with this core belief tell themselves that they must be in charge at all times. They like to call the shots in their life - so it's often their way or the highway.</Text>
            <Text>They resent not being independent or in control. They assume that if they are not 100% in charge of what they do, then they are weak. They believe that they shouldn’t have to do things simply because someone else says so.</Text>
            <Text>They feel angry and resentful when people impose tasks on them (e.g., by a supervisor, teacher or partner).</Text>
            <Text>They use procrastination as a way of easing their anger and sense of weakness. It helps them feel like they are in control because they are doing things on their own terms.</Text>
            <Button
              variant="outline"
              onPress={() => { navigation.goBack(); }}
            >
              Back
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default NeedForControl;