import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  ScrollView,
  Image,
} from "native-base";

function FearOfUncertainty({ navigation, route }) {
  return (
    <ScrollView>
      <Center py="3">
        <Container py="3">
          <VStack space="3">
            <Heading>Fear of uncertainty</Heading>
            <Text>Some people fear the unknown. They need to be very certain of what lies ahead. When they are uncertain they often assume the worst! Assuming the worst serves to prepare themselves should the worst happen.</Text>
            <Text>These types of people often assume things like “if I take action, then something bad will happen”. Or “I am better off not doing anything than risk it going bad”. </Text>
            <Text>In life it is hard to be 100% certain about anything. So when faced with tasks or goals where there is uncertainty they feel anxious and fearful. Things like making decisions, health check-ups or confronting a relationship problem can be big sources of worry.</Text>
            <Text>For these people procrastination is a way of alleviating their fears. They put off any action that could lead to an unknown or catastrophic outcome. Procrastination ensures nothing changes, and if nothing changes, nothing bad can happen. At least for the meantime! They temporarily feel more certain about things.</Text>
            <Text>Fear of success can also be a fear of uncertainty in disguise. That's because if you succeed then everything can change - creating more uncertainty.</Text>
            <Button
              variant="outline"
              onPress={() => { navigation.goBack(); }}
            >
              Back
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default FearOfUncertainty;