import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  Checkbox,
  ScrollView,
  Image,
  Box,
} from "native-base";
import { useStore } from "../../state";
import { track } from "@amplitude/analytics-react-native";
import { useColorScheme } from "react-native";

function Mission({ navigation, route }) {
  const completeModule = useStore((state) => state.completeModule);
  const scheme = useColorScheme()
  return (
    <ScrollView h={800}>
      <Center>
        <Container py="3">
          <VStack space="3">
            <Heading mt="3">Your mission this week: procrastination logging</Heading>
            <Text>
              Everyday we are going to ask you if there is a task you are
              procrastinating. We’ll prompt you to think about doing the task so
              that you can actually experience the procrastination reinforcement
              loop.
            </Text>
            <Text>You’ll be prompted to consider 2 things:</Text>
              <Box
                borderWidth="1"
                borderColor={
                  scheme === "dark" ? "coolGray.600" : "coolGray.300"
                }
                backgroundColor={
                  scheme === "dark" ? "coolGray.600" : "coolGray.300"
                }
                p="5"
                rounded="8"
              >
            <Text>• What did the initial discomfort feel like?</Text>
            <Text>
              • What rationalisations did you use to justify not doing the task?
            </Text>
            </Box>
            <Text>
              This is a simple exercise, but it’s extremely powerful when you do
              it consistently! Each day that you bring your awareness to the
              procrastination reinforcement loop, it gets a little bit weaker.
            </Text>
            <Button
              onPress={() => {
                completeModule(1);
                track("Exercise Completed", { exerciseNumber: 1 });
                navigation.reset({
                  index: 1,
                  routes: [
                    { name: "Journey" },
                    { name: "Home", params: { fireConfettiCannon: true, exerciseNumber: 1 } },
                  ],
                });
              }}
            >
              Finish
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Mission;
