import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useStore } from "../state";
import * as React from "react";
import {
  Image,
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  ScrollView,
  HStack,
  Badge,
  Box,
  Pressable,
  Spacer,
} from "native-base";
import image from "../assets/reflections.png";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useColorScheme } from "react-native";
import { track } from "@amplitude/analytics-react-native";
import { coreBeliefStatement, coreBeliefDescription } from "../constants";

const Stack = createNativeStackNavigator();

function Log({ navigation, route }) {
  const scheme = useColorScheme();
  const logEntries = useStore((state) => state.logEntries);
  return (
    <ScrollView>
      <Center py="6">
        <Image
          mt={4}
          source={image}
          width="300"
          height="200"
          alt="Procrastination App"
        />
        <Container py="3" w="full">
          <VStack space="6" w="full">
            <Heading mt="3">Your reflections</Heading>
            {logEntries.length === 0 && (
              <Text>
                As you add daily reflections, new insights will populate here
              </Text>
            )}
            {logEntries
              .sort(
                (a, b) =>
                  new Date(b.createdAt).getTime() -
                  new Date(a.createdAt).getTime()
              )
              .map((entry, index) => (
                <Pressable
                  key={entry.createdAt}
                  onPress={() =>
                    navigation.navigate("LogEntry", { entry: entry.createdAt })
                  }
                >
                  <Box
                    borderWidth="1"
                    borderColor="coolGray.300"
                    p="5"
                    rounded="8"
                  >
                    <HStack alignItems="center">
                      <Text fontSize={12}>
                        {new Intl.DateTimeFormat("en-GB", {
                          dateStyle: "full",
                          timeStyle: "short",
                        }).format(new Date(entry.createdAt))}
                      </Text>
                      <Spacer />
                      <Text fontSize={10}>{}</Text>
                    </HStack>
                    <HStack>
                      <Text mt="3" fontWeight="medium" fontSize="lg" maxW="80%">
                        {entry.task
                          ? entry.task
                          : `Reflection #${logEntries.length - index}`}
                      </Text>
                      <Spacer />
                      <MaterialCommunityIcons
                        name="chevron-right"
                        size={32}
                        color={scheme === "dark" ? "white" : "black"}
                      />
                    </HStack>
                  </Box>
                </Pressable>
              ))}
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

function decapitalizeFirstLetter(str: string) {
  if (!str) return "";
  if (str.charAt(0) === "I" && str.charAt(1) === " ")
    return str.replace(/\./g, "");
  return str.charAt(0).toLowerCase() + str.slice(1).replace(/\./g, "");
}

function LogEntry({ navigation, route }) {
  const logEntries = useStore((state) => state.logEntries);
  const entryDate = route.params.entry;
  const entry = logEntries.find((entry) => entry.createdAt === entryDate);

  React.useEffect(() => {
    track("Daily Reflection Viewed");
  }, []);
  return (
    <ScrollView>
      <Center>
        <Container py="3" w="full">
          <VStack space="3" w="full">
            {entry && (
              <>
                <Heading mt={3}>
                  On{" "}
                  {new Intl.DateTimeFormat("en-GB", {
                    dateStyle: "full",
                    timeStyle: "short",
                  }).format(new Date(entry.createdAt))}{" "}
                  🧘‍♀
                </Heading>
                {entry.positiveOutcome ? (
                  <>
                    <Text>
                      <Text>I'm procrastinating: </Text>
                      <Text bold highlight color="black" mb="3">
                        {entry.task}.
                      </Text>
                    </Text>

                    <Text>
                      <Text>Because it’s making me feel: </Text>
                      <Text bold highlight color="black" mb="3">
                        {entry.sensation}.
                      </Text>
                    </Text>

                    <Text>Avoiding this task has immediate benefits…</Text>
                    <Text bold highlight color="black" mb="3">
                      {entry.positiveOutcome}
                    </Text>

                    <Text>
                      However, putting off the task also has negative
                      consequences…
                    </Text>
                    <Text bold highlight color="black">
                      {entry.negativeOutcome}
                    </Text>
                    <Text>That makes me want to avoid the task even more!</Text>

                    <Text>
                      These outcomes mean that the next time I approach a
                      similar task, I’m likely to do the same all over again.
                    </Text>
                  </>
                ) : (
                  <>
                    <Text>
                      <Text>You procrastinated </Text>
                      <Text bold highlight color="black">
                        {decapitalizeFirstLetter(entry.task)}
                      </Text>
                      {entry.belief ? (
                        <>
                          <Text> because of the unhelpful belief that: </Text>
                          <Text bold highlight color="black">
                            {coreBeliefStatement[entry.belief]}
                          </Text>
                          <Text> That belief made you feel </Text>
                          <Text bold highlight color="black">
                            {decapitalizeFirstLetter(entry.sensation)}
                          </Text>
                          <Text>, so you are putting off the task.</Text>
                        </>
                      ) : (
                        <>
                          <Text> because it made you feel </Text>
                          <Text bold highlight color="black">
                            {decapitalizeFirstLetter(entry.sensation)}
                          </Text>
                          .
                        </>
                      )}
                    </Text>

                    {entry.belief && (
                      <Text bold highlight color="black">
                        {coreBeliefDescription[entry.belief]}
                      </Text>
                    )}

                    {entry.excuse ? (
                      <Text>
                        <Text>
                          To feel better about putting off the task, you told
                          yourself{" "}
                        </Text>
                        <Text bold highlight color="black">
                          {decapitalizeFirstLetter(entry.excuse)}
                        </Text>
                        .
                      </Text>
                    ) : (
                      <Text>
                        To make you feel more like getting started you modified
                        the task so that it was more specific and small enough
                        so that you can cross the “starting threshold”.
                      </Text>
                    )}

                    {entry.reflection ? (
                      <Text>
                        <Text>You also noticed that </Text>
                        <Text bold highlight color="black">
                          {decapitalizeFirstLetter(entry.reflection)}
                        </Text>
                        .
                      </Text>
                    ) : null}

                    {entry.belief && (
                      <Text>
                        This might also explain why you are procrastinating.
                      </Text>
                    )}
                  </>
                )}
              </>
            )}
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

function LogScreen({ navigation, route }) {
  return (
    <Stack.Navigator initialRouteName="Log">
      <Stack.Screen
        name="Log"
        component={Log}
        options={{ title: "Procrastination App" }}
      />
      <Stack.Screen
        name="LogEntry"
        component={LogEntry}
        options={{ title: "Reflection" }}
      />
    </Stack.Navigator>
  );
}

export default LogScreen;
