import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  TextArea,
  VStack,
  Checkbox,
  ScrollView,
  KeyboardAvoidingView,
  Image,
} from "native-base";
import { useStore } from "../../state";

function Discomfort({ navigation, route }) {
  const sensations = useStore((state) => state.logEntries).map(entry => entry.sensation);
  return (
    <ScrollView>
      <KeyboardAvoidingView behavior="padding">
      <Center py="3">
        <Container py="3">
          <VStack space="3">
            <Heading>More discomfort</Heading>
            <Text>In the short term, procrastination can relieve uncomfortable sensations. However, in the long term it can produce a different type of discomfort.</Text>
            <Text>The more you procrastinate the more you feel guilty or ashamed of your actions. You often feel more anxious because the tasks get harder and more overwhelming the longer you put them off.</Text>
            <Text>You may feel despair, as the longer you avoid it, the more you think you can’t tackle it.</Text>
            <Button
              variant="outline"
              onPress={() => {
                navigation.push("ReinforcementFactors", {
                  screen: "Backfiring",
                });
              }}
            >
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
      </KeyboardAvoidingView>
    </ScrollView>
  );
}

export default Discomfort;