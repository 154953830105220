import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  Checkbox,
  ScrollView,
} from "native-base";

function Rationalisations({ navigation, route }) {
  return (
    <ScrollView h="800">
      <Center>
        <Container py="3">
          <VStack space="3">
            <Heading>Identify common excuses</Heading>
            <VStack space="3">
              <Text>
                When we’re procrastinating, deep down we know we should be doing
                a more important task. This makes you feel guilt and shame.
              </Text>
              <Text>
                To help us get by and feel less guilty we often excuse our
                procrastination in some way. We come up with justifications that
                make it feel okay to put off the task. We often form these
                excuses unconsciously.
              </Text>
              <Text>
                In time we’ll help you become more aware of the common excuses
                you use. For now, select the procrastination excuses that you
                have used in the past.
              </Text>
            </VStack>
            <Button
              variant="outline"
              onPress={() => {
                navigation.push("UnderstandingProcrastination", { screen: "IdentifyExcuses" });
              }}
            >
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Rationalisations;