import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  ScrollView,
} from "native-base";

function NeedForPleasure({ navigation, route }) {
  return (
    <ScrollView>
      <Center py="3">
        <Container py="3">
          <VStack space="3">
            <Heading>Need for pleasure</Heading>
            <Text>Some people live their lives according to the principle that pleasure is paramount. They tend to be impulsive and have difficulty tolerating boredom. </Text>
            <Text>For these people it is hard to look beyond the short term, and be patient enough to wait for any long term pay off.  They often have rules like “life is too short to be doing things that are boring or hard, fun should always come first”. They may also assume things like “if I pass on fun, then I will become a boring lifeless drone”</Text>
            <Text>They feel frustrated and board when faced with uninteresting tasks like household chores. For them, procrastination is a way of alleviating boredom and frustration. It allows them to seek out more immediate pleasure.</Text>
            <Button
              variant="outline"
              onPress={() => { navigation.goBack(); }}
            >
              Back
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default NeedForPleasure;