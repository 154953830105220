import { createNativeStackNavigator } from "@react-navigation/native-stack";
import Complex from "./1Complex";
import Keeps from "./3Keeps";
import Common from "./4Common";
import Relieving from "./5Relieving";
import Pleasure from "./6Pleasure";
import Unchallenged from "./7Unchallenged";
import Negative from "./8Negative";
import Discomfort from "./9Discomfort";
import Backfiring from "./10Backfiring";
import Pressure from "./11Pressure";
import Mission from "./12Mission";
import Reflect from "./2Reflect"
const Stack = createNativeStackNavigator();

function ReinforcementFactors() {
  return (
    <Stack.Navigator
      initialRouteName="Welcome"
      screenOptions={{
        headerShown: false,
        title: "Exercise Two",
      }}
    >
      <Stack.Screen name="Complex" component={Complex} />
      <Stack.Screen name="Reflect" component={Reflect} />
      <Stack.Screen name="Keeps" component={Keeps} />
      <Stack.Screen name="Common" component={Common} />
      <Stack.Screen name="Relieving" component={Relieving} />
      <Stack.Screen name="Pleasure" component={Pleasure} />
      <Stack.Screen name="Unchallenged" component={Unchallenged} />
      <Stack.Screen name="Negative" component={Negative} />
      <Stack.Screen name="Discomfort" component={Discomfort} />
      <Stack.Screen name="Backfiring" component={Backfiring} />
      <Stack.Screen name="Pressure" component={Pressure} />
      <Stack.Screen name="Mission" component={Mission} />
    </Stack.Navigator>
  );
}

export default ReinforcementFactors;