import * as React from "react";
import { Heading, Button, Center, Container, Text, VStack } from "native-base";

function Overcoming({ navigation, route }) {
  return (
    <Center>
      <Container py="3">
        <VStack space="3">
          <Heading>Overcoming procrastination is a skill</Heading>
          <Text>
            It’s one of the most important skills you can learn in life.
            Procrastinating a little bit less can restore relationships, make
            great works of art, and even build entire companies.
          </Text>
          <Text>We are going to teach you that skill!</Text>
          <Button
            variant="outline"
            onPress={() => {
              navigation.push("UnderstandingProcrastination", { screen: "WhatsToCome" });
            }}
          >
            Continue
          </Button>
        </VStack>
      </Container>
    </Center>
  );
}

export default Overcoming;
