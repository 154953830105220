import * as React from "react";
import {
  Center,
  Container,
  Heading,
  Text,
  Image,
  ScrollView,
  Button,
  VStack,
  Box,
  HStack,
  Checkbox,
  Flex,
} from "native-base";
import * as Notifications from "expo-notifications";
import { useStore } from "../state";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useColorScheme } from "react-native";
import { track } from "@amplitude/analytics-react-native";
import chilling from "../assets/chilling.png";
import voidImage from "../assets/void.png";
import powerful from "../assets/powerful.png";
import creative from "../assets/creative.png";
import online from "../assets/online.png";
import productivity from "../assets/productivity.jpg";
import notifications from "../assets/notifications.png";
import deal from "../assets/deal.png";
import celebration from "../assets/celebration.png";

const Stack = createNativeStackNavigator();

const Screen = ({ children }) => (
  <Center w="full" flex="1">
    <Container w="full" flex="1">
      <Text my={16} bold alignSelf="center">
        Procrastination App
      </Text>
      {children}
    </Container>
  </Center>
);

const Chilling = ({ navigation }) => {
  React.useEffect(() => {
    track("Onboarding First Page Viewed");
  }, []);
  return (
    <ScrollView>
      <Screen>
        <Image
          source={chilling}
          width="310"
          height="220"
          alt="Procrastination App"
          mb="6"
          alignSelf="center"
        />
        <Text fontSize="xl" bold>
          Avoiding something important?
        </Text>
        <Text mb="8">Are there goals you never seem to make progress on?</Text>
        <Button
          marginTop="auto"
          mb="16"
          variant="outline"
          onPress={() => navigation.push("Void")}
          w="full"
        >
          Continue
        </Button>
      </Screen>
    </ScrollView>
  );
};

const Void = ({ navigation }) => (
  <ScrollView>
    <Screen>
      <Image
        source={voidImage}
        width="273"
        height="286"
        alt="Procrastination App"
        mb="12"
        alignSelf="center"
      />
      <Text fontSize="xl" bold>
        You're missing out on life itself!
      </Text>
      <Text mb="8">Procrastination makes small problems... BIG!</Text>
      <Button
        marginTop="auto"
        mb="16"
        variant="outline"
        onPress={() => navigation.push("Powerful")}
        w="full"
      >
        Continue
      </Button>
    </Screen>
  </ScrollView>
);

const Powerful = ({ navigation }) => (
  <ScrollView>
    <Screen>
      <Image
        source={powerful}
        width="350"
        height="240"
        alt="Procrastination App"
        mb="12"
      />
      <Text fontSize="xl" bold>
        Imagine a world without procrastination
      </Text>
      <Text mb="8">
        Where we call our loved ones and file taxes without anguish.
      </Text>
      <Button
        mb="16"
        marginTop="auto"
        variant="outline"
        onPress={() => navigation.push("Creative")}
        w="full"
      >
        Continue
      </Button>
    </Screen>
  </ScrollView>
);

const Creative = ({ navigation }) => (
  <ScrollView>
    <Screen>
      <Image
        source={creative}
        width="300"
        height="260"
        alt="Procrastination App"
        mb="6"
        alignSelf="center"
      />
      <Text fontSize="xl" bold>
        Overcoming procrastination is a skill
      </Text>
      <Text mb="8">
        Doing things that feel hard can restore relationships, make great works
        of art, and build entire companies.
      </Text>
      <Button
        mb="16"
        marginTop="auto"
        variant="outline"
        onPress={() => navigation.push("Online")}
        w="full"
      >
        Continue
      </Button>
    </Screen>
  </ScrollView>
);

const Online = ({ navigation }) => (
  <ScrollView>
    <Screen>
      <Image
        source={online}
        width="300"
        height="245"
        alt="Procrastination App"
        mb="10"
        alignSelf="center"
      />
      <Text fontSize="xl" bold>
        Procrastination App helps you do the hard things
      </Text>
      <Text mb="8">It helps you achieve what's important to you.</Text>
      <Button
        mb="16"
        marginTop="auto"
        variant="outline"
        onPress={() => navigation.push("Productivity")}
        w="full"
      >
        Continue
      </Button>
    </Screen>
  </ScrollView>
);

const Productivity = ({ navigation }) => (
  <ScrollView>
    <Screen>
      <Image
        source={productivity}
        width="350"
        height="320"
        alt="Procrastination App"
        mb="12"
      />
      <Text fontSize="xl" bold>
        This isn't a productivity hack
      </Text>
      <Text mb="8">
        It's a journey to self-discovery and meaningful change.
      </Text>
      <Button
        mb="16"
        marginTop="auto"
        variant="outline"
        onPress={() => navigation.push("YourJourney")}
        w="full"
      >
        Continue
      </Button>
    </Screen>
  </ScrollView>
);

const Journey = ({ navigation }) => {
  const scheme = useColorScheme();
  return (
    <ScrollView>
      <Screen>
        <Text fontSize="xl" bold mb="6" mt="-6">
          A three part journey to sustainable change 🚀
        </Text>

        <HStack w="full" mb="12">
          <Box
            flex="0"
            w="0"
            mr="12"
            borderStyle="solid"
            borderRadius="1"
            borderWidth="2"
            borderColor={scheme === "dark" ? "coolGray.600" : "coolGray.300"}
          ></Box>

          <VStack space="6" pr="12">
            <Box>
              <Box
                position="absolute"
                w="6"
                h="6"
                rounded="20"
                borderColor={
                  scheme === "dark" ? "coolGray.600" : "coolGray.300"
                }
                backgroundColor={scheme === "dark" ? "black" : "coolGray.100"}
                borderWidth="4"
                left="-62"
                bottom="45%"
              ></Box>
              <Box>
                <Text fontWeight="medium" fontSize="lg">
                  Understanding your psychology
                </Text>
                <Text>
                  Develop deep self-knowledge to enable long term change.
                </Text>
              </Box>
            </Box>

            <Box>
              <Box
                position="absolute"
                w="6"
                h="6"
                rounded="20"
                borderColor={
                  scheme === "dark" ? "coolGray.600" : "coolGray.300"
                }
                backgroundColor={scheme === "dark" ? "black" : "coolGray.100"}
                borderWidth="4"
                left="-62"
                bottom="45%"
              ></Box>
              <Box>
                <Text fontWeight="medium" fontSize="lg">
                  Overcoming procrastination
                </Text>
                <Text>
                  Learn the skills that actually help you overcome
                  procrastination.
                </Text>
              </Box>
            </Box>

            <Box>
              <Box
                position="absolute"
                w="6"
                h="6"
                rounded="20"
                borderColor={
                  scheme === "dark" ? "coolGray.600" : "coolGray.300"
                }
                backgroundColor={scheme === "dark" ? "black" : "coolGray.100"}
                borderWidth="4"
                left="-62"
                bottom="45%"
              ></Box>
              <Box>
                <Text fontWeight="medium" fontSize="lg">
                  Sustaining change
                </Text>
                <Text>
                  Create a personalized daily habit that ensures you achieve
                  life's most important goals.
                </Text>
              </Box>
            </Box>
          </VStack>
        </HStack>
        <Button
          mb="16"
          marginTop="auto"
          variant="outline"
          onPress={() => navigation.push("Notifications")}
          w="full"
        >
          Continue
        </Button>
      </Screen>
    </ScrollView>
  );
};

const RequestNotifications = ({ navigation }) => (
  <ScrollView>
    <Screen>
      <Image
        source={notifications}
        width="320"
        height="200"
        alignSelf="center"
        alt="Procrastination App"
        mb="12"
      />
      <Text fontSize="xl" bold>
        Keep on track with notifications
      </Text>
      <Text mb="8">
        We aim to keep notifications to a minimum. We only send notifications
        when you need them.
      </Text>
      <Button
        mb="16"
        marginTop="auto"
        variant="outline"
        onPress={async () => {
          await Notifications.requestPermissionsAsync();
          navigation.push("Deal");
        }}
        w="full"
      >
        Continue
      </Button>
    </Screen>
  </ScrollView>
);

const Deal = ({ navigation }) => {
  const [checklist, setChecklist] = React.useState([]);
  return (
    <ScrollView>
      <Screen>
        <Image
          source={deal}
          width="220"
          height="180"
          alt="Procrastination App"
          mb="8"
          mt="-8"
          alignSelf="center"
        />
        <Text fontSize="xl" bold>
          Commit to change
        </Text>
        <Text mb="3">
          Changing your procrastination habits won't happen by accident. Make a
          commitment.
        </Text>
        <Text mb="3">I commit to:</Text>
        <Checkbox.Group
          defaultValue={[]}
          accessibilityLabel="I commit to:"
          onChange={(values) => {
            setChecklist(values || []);
          }}
          mb="3"
        >
          {[
            "Being honest with myself",
            "Reflecting for 1 minute every day",
          ].map((item) => (
            <Checkbox key={`checklist-${item}`} value={item}>
              {item}
            </Checkbox>
          ))}
        </Checkbox.Group>
        <Button
          mb="16"
          marginTop="auto"
          variant="outline"
          w="full"
          isDisabled={checklist.length < 2}
          onPress={() => navigation.push("Celebration")}
        >
          Continue
        </Button>
      </Screen>
    </ScrollView>
  );
};

const Celebration = ({ navigation }) => {
  const completeModule = useStore((state) => state.completeModule);
  React.useEffect(() => {
    track("Onboarding Final Page Viewed");
  }, []);
  return (
    <ScrollView>
      <Screen>
        <Image
          source={celebration}
          width="330"
          height="260"
          alt="Procrastination App"
          mb="12"
          alignSelf="center"
        />
        <Text fontSize="xl" bold>
          Well done 🎉
        </Text>
        <Text mb="6">
          You're on your way to achieving life's most important goals!
        </Text>
        <Text mb="6">Start exploring Procrastination App.</Text>
        <Button
          mb="16"
          marginTop="auto"
          onPress={() => {
            completeModule(0);
            track("Exercise Completed", { exerciseNumber: 0 });
            navigation.reset({ index: 0, routes: [{ name: "Main" }] });
          }}
          w="full"
        >
          Let's do it
        </Button>
      </Screen>
    </ScrollView>
  );
};

function Onboarding() {
  return (
    <Stack.Navigator
      initialRouteName="Chilling"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="Chilling" component={Chilling} />
      <Stack.Screen name="Void" component={Void} />
      <Stack.Screen name="Powerful" component={Powerful} />
      <Stack.Screen name="Creative" component={Creative} />
      <Stack.Screen name="Online" component={Online} />
      <Stack.Screen name="Productivity" component={Productivity} />
      <Stack.Screen name="YourJourney" component={Journey} />
      <Stack.Screen name="Notifications" component={RequestNotifications} />
      <Stack.Screen name="Deal" component={Deal} />
      <Stack.Screen name="Celebration" component={Celebration} />
    </Stack.Navigator>
  );
}

export default Onboarding;
