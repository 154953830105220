import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  TextArea,
  VStack,
  Checkbox,
  ScrollView,
  KeyboardAvoidingView,
  Image,
} from "native-base";
import { useStore } from "../../state";

function Backfiring({ navigation, route }) {
  const sensations = useStore((state) => state.logEntries).map(entry => entry.sensation);
  return (
    <ScrollView>
      <KeyboardAvoidingView behavior="padding">
      <Center py="3">
        <Container py="3">
          <VStack space="3">
            <Heading>Backfiring self-criticism</Heading>
            <Text>People will often beat themselves up the more they procrastinate. They say things to themselves like “you’re so lazy, pull yourself together and get started!”</Text>
            <Text>The intention of doing this is often to motivate yourself into action. It’s like a ‘tough love’ approach. But this generally backfires!</Text>
            <Text>When you chastise yourself, you build up pressure which makes you want to avoid the task even more!</Text>
            <Button
              variant="outline"
              onPress={() => {
                navigation.push("ReinforcementFactors", {
                  screen: "Pressure",
                });
              }}
            >
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
      </KeyboardAvoidingView>
    </ScrollView>
  );
}

export default Backfiring;