export enum Categories {
  Worthwhile = "Worthwhile",
  Willingness = "Willingness",
  Ability = "Ability",
}

export const questions = [
  {
    reverse: true,
    category: Categories.Worthwhile,
    title:
      "I see little or no benefit to putting time and energy into trying to overcome procrastination.",
  },
  {
    reverse: false,
    category: Categories.Willingness,
    title:
      "I am interested in finding new ways to stop putting off important tasks.",
  },
  {
    reverse: true,
    category: Categories.Worthwhile,
    title:
      "Working to change my procrastination habits has only a little payoff or benefit.",
  },
  {
    reverse: true,
    category: Categories.Ability,
    title:
      "I don’t have enough time to regularly use this app and follow the recommendations ",
  },
  {
    reverse: false,
    category: Categories.Willingness,
    title:
      "I am ready to do more to better manage my tendency to procrastinate.",
  },
  {
    reverse: false,
    category: Categories.Ability,
    title:
      "I am able to make the changes in my life that are needed to overcome procrastination.",
  },
  {
    reverse: true,
    category: Categories.Worthwhile,
    title:
      "It is not really worth following the recommendations in this app to overcome procrastination",
  },
  {
    reverse: false,
    category: Categories.Willingness,
    title: "I want to find a better way to overcome procrastination.",
  },
  {
    reverse: false,
    category: Categories.Ability,
    title:
      "I am able to integrate the recommendations from this app into my life.",
  },
];
