import * as React from "react";
import {
  Box,
  Heading,
  Button,
  Center,
  Container,
  Text,
  TextArea,
  VStack,
  ScrollView,
  KeyboardAvoidingView,
} from "native-base";
import { useStore } from "../../state";
import { useColorScheme } from "react-native";

function WhatTasks({ navigation, route }) {
  const logEntries = useStore((state) => state.logEntries);
  const tasks = logEntries.map((entry) => entry.task);
  const uniqueTasks = [...new Set(tasks)];
  const insight = useStore((state) => state.insight);
  const setInsight = useStore((state) => state.setInsight);
  const scheme = useColorScheme();
  return (
    <ScrollView h={800}>
      <KeyboardAvoidingView behavior="padding">
        <Center>
          <Container py="3">
            <VStack space="3">
              <Heading>What tasks did you procrastinate on?</Heading>
              <Text>
                Listed below are all of the tasks you said you procrastinated.
                The fact that you procrastinated these tasks means that they all
                cause you discomfort.
              </Text>
              <Text>Tasks you've procrastinated starting:</Text>
              {uniqueTasks.slice(0, 5).map((task) => (
                <Box
                  key={task}
                  borderWidth="1"
                  borderColor={
                    scheme === "dark" ? "coolGray.600" : "coolGray.300"
                  }
                  backgroundColor={
                    scheme === "dark" ? "coolGray.600" : "coolGray.300"
                  }
                  p="5"
                  rounded="8"
                >
                  <Text>{task}</Text>
                </Box>
              ))}
              <Text>
                This is an opportunity to learn something about yourself!
              </Text>
              <Text>
                What is it about these tasks that made you feel uncomfortable?
                If you notice any patterns capture it as an insight👇
              </Text>
              <TextArea
                placeholder="All of the tasks I procrastinate tend to…"
                autoCompleteType="text"
                value={insight}
                onChangeText={setInsight}
              />
              <Button
                variant="outline"
                onPress={() => {
                  navigation.push("DefiningAchievableTasks", {
                    screen: "TaskFeatures",
                  });
                }}
              >
                Continue
              </Button>
            </VStack>
          </Container>
        </Center>
      </KeyboardAvoidingView>
    </ScrollView>
  );
}

export default WhatTasks;
