import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  Checkbox,
  ScrollView,
  Image,
} from "native-base";

function BreakingUp({ navigation, route }) {
  return (
    <ScrollView h={800}>
      <Center>
        <Container py="3">
          <VStack space="3">
            <Heading>
              Make tasks smaller to break the starting threshold
            </Heading>
            <Text>
              Chronic procrastinators often have grand plans. However, the
              ‘grandness’ of those plans is usually what overwhelms us and
              prevents us from taking action.
            </Text>
            <Text>
              A strategy for overcoming this state is to keep reducing the size
              of your goal until you’re able to break through the "getting
              started threshold”. You can break things down into ridiculously
              small steps! Writing an essay can be broken down to opening the
              document and writing one sentence.
            </Text>
            <Text>
              Breaking that “starting threshold” often naturally flows into
              larger blocks of progress. Just be careful not to put pressure on
              yourself that it needs to!
            </Text>
            <Button
              variant="outline"
              onPress={() => {
                navigation.push("DefiningAchievableTasks", {
                  screen: "Planning",
                });
              }}
            >
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default BreakingUp;
