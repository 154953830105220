import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  TextArea,
  VStack,
  KeyboardAvoidingView,
} from "native-base";
import { useStore } from "../../state";

function Reflections({ navigation, route }) {
  const newLogEntry = useStore((state) => state.newLogEntry);
  const setNewLogEntry = useStore((state) => state.setNewLogEntry);
  const [reflection, setReflection] = React.useState("");
  const complete = (reflection: string) => {
    setNewLogEntry({ ...newLogEntry, reflection });
    navigation.push("Reflect", { screen: "Review" });
  };
  return (
    <KeyboardAvoidingView behavior="padding">
      <Center>
        <Container py="3">
          <VStack space="3">
            <Heading size="lg" mt="3">
              Any other reflections?
            </Heading>
            <Text>
              Did you notice anything else important that might explain why you
              were procrastinating?
            </Text>
            <TextArea
              placeholder="Type your reflections here..."
              autoCompleteType="text"
              value={reflection}
              onChangeText={setReflection}
            />
            <Button onPress={() => complete(reflection)}>Continue</Button>
            <Button variant="outline" onPress={() => complete("")}>
              Nothing else
            </Button>
          </VStack>
        </Container>
      </Center>
    </KeyboardAvoidingView>
  );
}

export default Reflections;
