import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  Checkbox,
  ScrollView,
} from "native-base";

function Activities({ navigation, route }) {
  return (
    <ScrollView h="800">
      <Center>
        <Container py="3">
          <VStack space="3">
            <Heading>Procrastination activities</Heading>
            <Text>
              When procrastinating you replace important tasks with things that
              are not a priority. Think about the typical things you do instead
              of the task at hand.
            </Text>
            <Button
              variant="outline"
              onPress={() => {
                navigation.push("UnderstandingProcrastination", {
                  screen: "IdentifyDistractions",
                });
              }}
            >
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Activities;