import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  ScrollView,
} from "native-base";

function LackOfSelfConfidence({ navigation, route }) {
  return (
    <ScrollView>
      <Center py="3">
        <Container py="3">
          <VStack space="3">
            <Heading>Lack of self-confidence</Heading>
            <Text>Some people don’t think much of themselves generally. They doubt their abilities, and lack confidence that they are capable individuals who can tackle things that come their way.</Text>
            <Text>They often assume that trying things will reveal their inadequacies. So for them it is safer not to try! New or challenging tasks make them feel depressed and despairing. Things like new work responsibilities or confronting a family member are a struggle.</Text>
            <Text>These people use procrastination as a way of protecting themselves. By not trying or giving up at a task they can avoid having to face their supposed flaws. By procrastinating they never put themselves in challenging situations that test their capabilities.</Text>
            <Button
              variant="outline"
              onPress={() => { navigation.goBack(); }}
            >
              Back
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default LackOfSelfConfidence;