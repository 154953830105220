import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  Checkbox,
  ScrollView,
  Image,
} from "native-base";
import image from "../../assets/cognitive-triangle-stealing.jpg"

function CoreBeliefs({ navigation, route }) {
  return (
    <ScrollView h={800}>
      <Center py="3">
        <Container py="3">
          <VStack space="3">
            <Heading>Core beliefs drive discomfort</Heading>
            <Text>The core beliefs we pick up from life experiences drive how we feel about doing certain tasks.</Text>
            <Text>
            For example, from a young age I was told that stealing is wrong and thieves will be punished. Because of that, if you told me to walk into a store and steal something I would immediately feel tense and anxious. My mind would start racing through different scenarios where I get punished. More than likely I would find a way to avoid the situation.
            </Text>
            <Image source={image} width="300" height="300" alt="Cognitive triangle - stealing" />
            <Button
              variant="outline"
              onPress={() => {
                navigation.push("Beliefs", {
                  screen: "Unhelpful",
                });
              }}
            >
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default CoreBeliefs;