import * as React from "react";
import {
  Box,
  Heading,
  Button,
  Center,
  Container,
  Text,
  TextInput,
  VStack,
  ScrollView,
} from "native-base";
import { useColorScheme } from "react-native";
import { useStore } from "../../../state";

function Willingness({ navigation, route }) {
  const readinessForChange = useStore((state) => state.readinessForChange);
  const scheme = useColorScheme();
  return (
    <ScrollView>
      <Center py="3">
        <Container py="3">
          <VStack space="3">
            <Heading>Willingness to change</Heading>
            {readinessForChange.willingness > 10 ? (
              <>
                <Text>
                  Your willingness to change your procrastination habits is
                  strong 💪
                </Text>
                <Text>
                  That is great! Being willing to change is an essential
                  ingredient in behavior change.
                </Text>
              </>
            ) : (
              <Text>
                Your willingness to change your procrastination habits is weak.
                That is totally fine! You might not be ready to change your
                behavior. 🤷 The decision is totally yours to make.
              </Text>
            )}
            <Text>
              Nonetheless it’s worth considering how your current habits are
              serving you today. Do you feel like your habits are helping you
              live the life you want to? 🤔
            </Text>
            <Text>
              If you are up for it, ask yourself the following questions:
            </Text>
            <Box
              borderWidth="1"
              borderColor={scheme === "dark" ? "coolGray.600" : "coolGray.300"}
              backgroundColor={
                scheme === "dark" ? "coolGray.600" : "coolGray.300"
              }
              p="5"
              rounded="8"
            >
              <Text mb="3">
                How is procrastinating important tasks in my life...{" "}
                <Text bold>hurting me?</Text>
              </Text>
              <Text>
                How is procrastinating important tasks in my life...{" "}
                <Text bold>helping me?</Text>
              </Text>
            </Box>
            <Text>
              Looking at your responses, do you think the hurt procrastination
              brings you outweighs how much it helps you?
            </Text>
            <Text>
              When times get tough, reflect on your responses to these
              questions. It will remind you why you made a commitment to change
              and motivate you to keep going!
            </Text>
            <Button
              variant="outline"
              onPress={() => {
                navigation.goBack();
              }}
            >
              Back
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Willingness;
