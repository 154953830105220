import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  Checkbox,
  ScrollView,
  Image,
} from "native-base";

function Unclear({ navigation, route }) {
  return (
    <ScrollView h={800}>
      <Center>
        <Container py="3">
          <VStack space="3">
            <Heading>Unclear tasks makes our brain uncomfortable</Heading>
            <Text>
              We sometimes set goals where the specific actions we need to take
              are not clear. Here are a few examples:
            </Text>
            <Text>• Lose weight</Text>
            <Text>• Finish my English essay</Text>
            <Text>
              In these examples, it isn’t exactly clear what you need to do.
              This ambiguity makes our brain uncomfortable. The uncertainty
              about what exactly we need to do creates an uncomfortable feeling
              that we are driven to escape.
            </Text>
            <Button
              variant="outline"
              onPress={() => {
                navigation.push("DefiningAchievableTasks", {
                  screen: "Specific",
                });
              }}
            >
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Unclear;
