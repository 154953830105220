import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  Checkbox,
  ScrollView,
  Image,
} from "native-base";
import { useStore } from "../../state";
import { track } from "@amplitude/analytics-react-native";

function Mission({ navigation, route }) {
  const completeModule = useStore((state) => state.completeModule);
  return (
    <ScrollView h={800}>
      <Center py="3">
        <Container py="3">
          <VStack space="3">
            <Heading>Your mission this week!</Heading>
            <Text>
              This week you are going to take notice of which unhelpful beliefs
              are driving your procrastination.
            </Text>
            <Text>
              As you begin to notice which beliefs are driving your
              procrastination you might find yourself challenging them. If so,
              that’s great! However, you don’t have to. At this stage we are
              simply trying to build your awareness of the beliefs driving your
              procrastination.
            </Text>
            <Button
              onPress={() => {
                completeModule(2);
                track("Exercise Completed", { exerciseNumber: 2 });
                navigation.reset({
                  index: 1,
                  routes: [
                    { name: "Journey" },
                    { name: "Home", params: { fireConfettiCannon: true, exerciseNumber: 2 } },
                  ],
                });
              }}
            >
              Finish
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Mission;
