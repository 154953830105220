import * as React from "react";
import { Heading, Button, Center, Container, Text, VStack } from "native-base";
import { useStore } from "../../state";
import { track } from "@amplitude/analytics-react-native";
import { ScrollView } from "react-native-gesture-handler";
import { coreBeliefStatement, coreBeliefDescription } from "../../constants";

function decapitalizeFirstLetter(str: string) {
  if (str === null || str === undefined) return "";
  if (str.charAt(0) === "I" && str.charAt(1) === " ")
    return str.replace(/\./g, "");
  return str.charAt(0).toLowerCase() + str.slice(1).replace(/\./g, "");
}

function Review({ navigation, route }) {
  const newLogEntry = useStore((state) => state.newLogEntry);
  const setNewLogEntry = useStore((state) => state.setNewLogEntry);
  const addLogEntry = useStore((state) => state.addLogEntry);
  const complete = async () => {
    track("Task Completed");
    addLogEntry({
      ...newLogEntry,
      task: route?.params?.newTask ? route?.params?.newTask : newLogEntry.task,
    });
    setNewLogEntry({ task: "", excuse: "", sensation: "", reflection: "" });
    navigation.push("Reflect", { screen: "Success" });
  };

  return (
    <ScrollView>
      <Center>
        <Container py="3">
          <VStack space="3">
            {newLogEntry.positiveOutcome ? (
              <>
                <Heading size="lg" mt="3">
                  Review your procrastination reinforcement loop 🧘‍♀️
                </Heading>
                <Text>
                  <Text>I'm procrastinating: </Text>
                  <Text bold highlight color="black" mb="3">
                    {newLogEntry.task}.
                  </Text>
                </Text>

                <Text>
                  <Text>Because it’s making me feel: </Text>
                  <Text bold highlight color="black" mb="3">
                    {newLogEntry.sensation}.
                  </Text>
                </Text>

                <Text>Avoiding this task has immediate benefits…</Text>
                <Text bold highlight color="black" mb="3">
                  {newLogEntry.positiveOutcome}
                </Text>

                <Text>
                  However, putting off the task also has negative consequences…
                </Text>
                <Text bold highlight color="black">
                  {newLogEntry.negativeOutcome}
                </Text>
                <Text>That makes me want to avoid the task even more!</Text>

                <Text>
                  These outcomes mean that the next time I approach a similar
                  task, I’m likely to do the same all over again.
                </Text>
              </>
            ) : (
              <>
                <Heading size="lg" mt="3">
                  Take a moment to review your procrastination loop 🧘‍♀️
                </Heading>
                <Text>
                  <Text>You’re procrastinating </Text>
                  <Text bold highlight color="black">
                    {decapitalizeFirstLetter(newLogEntry.task)}
                  </Text>
                  {newLogEntry.belief ? (
                    <>
                      <Text> because of the unhelpful belief that: </Text>
                      <Text bold highlight color="black">
                        {coreBeliefStatement[newLogEntry.belief]}
                      </Text>
                      <Text> That belief made you feel </Text>
                      <Text bold highlight color="black">
                        {decapitalizeFirstLetter(newLogEntry.sensation)}
                      </Text>
                      <Text>, so you are putting off the task.</Text>
                    </>
                  ) : (
                    <>
                      <Text> because it’s making you feel </Text>
                      <Text bold highlight color="black">
                        {decapitalizeFirstLetter(newLogEntry.sensation)}
                      </Text>
                      .
                    </>
                  )}
                </Text>

                {newLogEntry.belief && (
                  <Text bold highlight color="black">
                    {coreBeliefDescription[newLogEntry.belief]}
                  </Text>
                )}

                {route?.params?.newTask ? (
                  <>
                    <Text>
                      To make you feel more like getting started you modified
                      the task so that it was more specific and small enough so
                      that you can cross the “starting threshold”.
                    </Text>
                    <Text>
                      Your task is now:{" "}
                      <Text bold highlight color="black">
                        {route?.params?.newTask}
                      </Text>
                    </Text>
                  </>
                ) : (
                  <Text>
                    <Text>
                      To feel better about putting off the task, you told
                      yourself{" "}
                    </Text>
                    <Text bold highlight color="black">
                      {decapitalizeFirstLetter(newLogEntry.excuse)}
                    </Text>
                    .
                  </Text>
                )}

                {newLogEntry.reflection ? (
                  <Text>
                    <Text>You also noticed that </Text>
                    <Text bold highlight color="black">
                      {decapitalizeFirstLetter(newLogEntry.reflection)}
                    </Text>
                    .
                  </Text>
                ) : null}

                {newLogEntry.belief && (
                  <Text>
                    This might also explain why you are procrastinating.
                  </Text>
                )}
              </>
            )}
            <Button mt={3} onPress={() => complete()}>
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Review;
