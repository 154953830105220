import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  ScrollView,
  Box,
  Flex,
  Progress,
  Pressable,
} from "native-base";
import { useColorScheme } from "react-native";
import { useStore } from "../../state";
import { MaterialCommunityIcons } from "@expo/vector-icons";

function computeSeverity(score: number) {
  if (score > 10) {
    return { color: "emerald", label: "Strong" };
  }
  return { color: "primary", label: "Weak" };
}

function Readiness({ navigation, route }) {
  const readinessForChange = useStore((state) => state.readinessForChange);
  const undoResponse = useStore((state) => state.undoQuizResponse);
  const scheme = useColorScheme();

  React.useEffect(
    () =>
      navigation.addListener("beforeRemove", () => {
        undoResponse();
      }),
    [navigation]
  );

  const renderBelief = (route, title, score, last?) => (
    <Pressable onPress={() => navigation.push("Change", { screen: route })}>
      <Flex
        justifyContent="space-between"
        flexDir="row"
        alignItems="center"
        mt="3"
      >
        <Text mr="2" maxW="50%">
          {title}
        </Text>
        <Progress
          value={(score / 20) * 100}
          maxW="40%"
          flex="1"
          marginLeft="auto"
          colorScheme={computeSeverity(score).color}
        />
        <MaterialCommunityIcons
          name="chevron-right"
          size={32}
          color={scheme === "dark" ? "white" : "black"}
        />
      </Flex>
      <Box
        borderBottomWidth={last ? "0" : "2"}
        borderBottomColor="gray.600"
        pb="6"
      >
        <Text fontSize="12">{computeSeverity(score).label}</Text>
      </Box>
    </Pressable>
  );

  return (
    <ScrollView h={800}>
      <Center py="3">
        <Container py="3">
          <VStack space="3">
            <Heading>Your readiness for change</Heading>
            <Text>
              There are three crucial motivational factors that predict
              successful behaviour change. Select each one to understand how you
              can increase your chances of successful behaviour change.
            </Text>
            {renderBelief(
              "Willingness",
              "Willingness to change",
              readinessForChange.willingness
            )}
            {renderBelief(
              "Worthwhile",
              "Belief that change is worthwhile",
              readinessForChange.worthwhile
            )}
            {renderBelief(
              "Ability",
              "Belief in ability to change",
              readinessForChange.ability,
              true
            )}
            <Button
              variant="outline"
              onPress={() => {
                navigation.push("Change", {
                  screen: "Commitment",
                });
              }}
            >
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Readiness;
