import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  TextArea,
  VStack,
  Checkbox,
  ScrollView,
  KeyboardAvoidingView,
  Image,
  Box,
} from "native-base";
import { useStore } from "../../state";
import { useColorScheme } from "react-native";
import { FontAwesome5 } from "@expo/vector-icons";
import { coreBeliefStatement } from "../../constants";

function Reflect({ navigation, route }) {
  const setInsight = useStore((state) => state.setInsight);
  const logEntries = useStore((state) => state.logEntries);
  const scheme = useColorScheme();
  return (
    <ScrollView>
      <KeyboardAvoidingView behavior="padding">
        <Center py="3">
          <Container py="3">
            <VStack space="3">
              <Heading>
                Reflect on the core beliefs causing you discomfort
              </Heading>
              <Text>
                These are the recent tasks you’ve been procrastinating. Take
                note of the beliefs that triggered a feeling of discomfort and
                the urge to avoid them.
              </Text>
              {logEntries
                .sort(
                  (a, b) =>
                    new Date(b.createdAt).getTime() -
                    new Date(a.createdAt).getTime()
                )
                .filter((entry) => entry.belief)
                .splice(0, 3)
                .map((entry) => (
                  <Box
                    key={entry.createdAt}
                    borderWidth="1"
                    borderColor={
                      scheme === "dark" ? "coolGray.600" : "coolGray.300"
                    }
                    backgroundColor={
                      scheme === "dark" ? "coolGray.600" : "coolGray.300"
                    }
                    p="5"
                    rounded="8"
                  >
                    <Text>
                      <Text bold>Task: </Text>
                      {entry.task}
                    </Text>
                    <Box alignSelf="center">
                      <FontAwesome5
                        name="long-arrow-alt-down"
                        size={24}
                        color={scheme === "dark" ? "white" : "black"}
                      />
                    </Box>
                    <Text>
                      <Text bold>Belief: </Text>
                      {coreBeliefStatement[entry.belief]}
                    </Text>
                    <Box alignSelf="center">
                      <FontAwesome5
                        name="long-arrow-alt-down"
                        size={24}
                        color={scheme === "dark" ? "white" : "black"}
                      />
                    </Box>
                    <Text>
                      <Text bold>Sensation: </Text>
                      {entry.sensation}
                    </Text>
                  </Box>
                ))}
              <Text>
                What inaccurate or inflexible belief causes you to procrastinate
                important tasks? Capture it as an insight!
              </Text>
              <TextArea
                placeholder="Example: My need for control makes me avoid writing reports because my boss calls the shots..."
                autoCompleteType="text"
                onChangeText={setInsight}
              />
              <Button
                variant="outline"
                onPress={() => {
                  navigation.push("ReinforcementFactors", {
                    screen: "Keeps",
                  });
                }}
              >
                Continue
              </Button>
            </VStack>
          </Container>
        </Center>
      </KeyboardAvoidingView>
    </ScrollView>
  );
}

export default Reflect;
