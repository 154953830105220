import * as React from "react";
import { Heading, Button, Center, Container, Text, VStack } from "native-base";

function Introduction({ navigation, route }) {
  return (
    <Center>
      <Container py="4">
        <VStack space="4">
          <Heading>Welcome 👋</Heading>
          <Text>
            You’re probably here because you're avoiding doing something you
            know is important. Nonetheless, this is an exciting moment!
          </Text>
          <Text>
            This is the beginning of your journey towards overcoming
            procrastination and the pursuit of what's most important to you.
          </Text>
          <Text>Let’s get into it 🚀</Text>
          <Button
            variant="outline"
            onPress={() => {
              navigation.push("UnderstandingProcrastination", { screen: "Overcoming" });
            }}
          >
            Continue
          </Button>
        </VStack>
      </Container>
    </Center>
  );
}

export default Introduction;
