import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import Button from "./Button";
import What from "./What";
import Modify from "./Modify";
import Excuse from "./Excuse";
import Sensation from "./Sensation";
import Reflections from "./Reflections";
import Success from "./Success";
import Review from "./Review";
import Beliefs from "./Beliefs";
import PositiveOutcome from "./PositiveOutcome";
import NegativeOutcome from "./NegativeOutcome";

const Stack = createNativeStackNavigator();

function Reflect() {
  return (
    <Stack.Navigator
      initialRouteName="Button"
      screenOptions={{ headerShown: false, title: "Procrastination App" }}
    >
      <Stack.Screen name="What" component={What} />
      <Stack.Screen name="Beliefs" component={Beliefs} />
      <Stack.Screen name="Modify" component={Modify} />
      <Stack.Screen name="Excuse" component={Excuse} />
      <Stack.Screen name="Sensation" component={Sensation} />
      <Stack.Screen name="PositiveOutcome" component={PositiveOutcome} />
      <Stack.Screen name="NegativeOutcome" component={NegativeOutcome} />
      <Stack.Screen name="Reflections" component={Reflections} />
      <Stack.Screen name="Review" component={Review} />
      <Stack.Screen name="Success" component={Success} />
    </Stack.Navigator>
  );
}

export default Reflect;
