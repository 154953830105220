

import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  Checkbox,
  ScrollView,
  Image,
} from "native-base";

function Common({ navigation, route }) {
  return (
    <ScrollView>
      <Center py="3">
        <Container py="3">
          <VStack space="3">
            <Heading>Common reinforcement factors</Heading>
            <Text>We’ll start by exploring three of the most common positive outcomes of putting off a task that keep people procrastinating:</Text>
            <Text>• Relieving discomfort</Text>
            <Text>• Experiencing pleasure</Text>
            <Text>• Leaving core beliefs unchallenged</Text>
            <Button
              variant="outline"
              onPress={() => {
                navigation.push("ReinforcementFactors", {
                  screen: "Relieving",
                });
              }}
            >
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Common;