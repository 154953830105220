import create from "zustand";
import { API, graphqlOperation } from "aws-amplify";
import {
  createCoreBeliefs,
  createLogEntry,
  createModuleCompletion,
} from "./graphql/mutations";
import {
  listCoreBeliefs,
  listLogEntries,
  listModuleCompletions,
} from "./graphql/queries";
import { persist } from "zustand/middleware";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Categories } from "./screens/Beliefs/questions";
import { Categories as ReadinessCategories } from "./screens/Change/questions";

interface AppState {
  loading: boolean;
  tasks: string[];
  sensations: string[];
  excuses: string[];
  insight: string;
  moduleCompletions: Module[];
  completeModule: (number: number) => void;
  logEntries: LogEntry[];
  newLogEntry: LogEntryCreate;
  setNewLogEntry: (entry: LogEntryCreate) => void;
  addLogEntry: (entry: LogEntryCreate) => void;
  setTasks: (tasks: string[]) => void;
  setExcuses: (excuses: string[]) => void;
  setSensations: (sensations: string[]) => void;
  setInsight: (insight: string) => void;
  quizResponses: Array<any>;
  setQuizResponses: (response: any) => void;
  coreBeliefs: CoreBeliefs;
  setCoreBeliefs: () => void;
  fetch: () => void;
}

export interface CoreBeliefs {
  needToBeInCharge: number;
  pleasureSeeking: number;
  fearOfFailure: number;
  fearOfUncertainty: number;
  lowSelfConfidence: number;
  depletedEnergy: number;
}

export interface LogEntry {
  createdAt: string;
  task: string;
  excuse: string;
  sensation: string;
  reflection: string;
  belief: string;
  positiveOutcome: string;
  negativeOutcome: string;
}

export interface LogEntryCreate {
  task: string;
  excuse: string;
  sensation: string;
  reflection: string;
}

export interface Module {
  createdAt: string;
  number: number;
}

export const useStore = create()(
  persist(
    (set, get) => ({
      loading: true,
      setLoading: (loadingState: boolean) => set({ loading: loadingState }),
      user: null,
      tasks: [],
      sensations: [],
      excuses: [],
      distractions: [],
      logEntries: [],
      moduleCompletions: [],
      insight: "",
      quizResponses: [],
      positiveOutcomes: [],
      negativeOutcomes: [],
      setQuizResponses: (response) => {
        set({ quizResponses: [...get().quizResponses, response] });
      },
      undoQuizResponse: () => {
        let responses = get().quizResponses;
        responses.pop();
        set({ quizResponses: responses });
      },
      readinessForChange: {
        worthwhile: 0,
        willingness: 0,
        ability: 0,
      },
      setReadinessForChange: async () => {
        let result = {
          worthwhile: 0,
          willingness: 0,
          ability: 0,
        };

        get().quizResponses.forEach((response) => {
          switch (response.category) {
            case ReadinessCategories.Worthwhile:
              result.worthwhile += response.score;
              break;
            case ReadinessCategories.Willingness:
              result.willingness += response.score;
              break;
            case ReadinessCategories.Ability:
              result.ability += response.score;
              break;
          }
        });

        set({ readinessForChange: result });
        const record = await API.graphql({
          query: createReadinessForChange,
          variables: { input: { ...result } },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
      },
      coreBeliefs: {
        needToBeInCharge: 0,
        pleasureSeeking: 0,
        fearOfFailure: 0,
        fearOfUncertainty: 0,
        lowSelfConfidence: 0,
        depletedEnergy: 0,
      },
      setCoreBeliefs: async () => {
        let result = {
          needToBeInCharge: 0,
          pleasureSeeking: 0,
          fearOfFailure: 0,
          fearOfUncertainty: 0,
          lowSelfConfidence: 0,
          depletedEnergy: 0,
        };

        get().quizResponses.forEach((response) => {
          switch (response.belief) {
            case Categories.NeedForPleasure:
              result.pleasureSeeking += response.score;
              break;
            case Categories.FearOfJudgement:
              result.fearOfFailure += response.score;
              break;
            case Categories.FearOfUncertainty:
              result.fearOfUncertainty += response.score;
              break;
            case Categories.LackOfSelfConfidence:
              result.lowSelfConfidence += response.score;
              break;
            case Categories.IntoleranceOfLowEnergy:
              result.depletedEnergy += response.score;
              break;
            case Categories.NeedForControl:
              result.needToBeInCharge += response.score;
              break;
          }
        });

        set({ coreBeliefs: result });
        const record = await API.graphql({
          query: createCoreBeliefs,
          variables: { input: { ...result } },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
      },
      completeModule: async (number: number) => {
        const record = await API.graphql({
          query: createModuleCompletion,
          variables: { input: { number, insight: get().insight } },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        set({
          moduleCompletions: [
            ...get().moduleCompletions,
            record.data.createModuleCompletion,
          ],
        });
      },
      newLogEntry: { task: "", excuse: "", sensation: "", reflection: "" },
      setNewLogEntry: (entry: LogEntryCreate) => set({ newLogEntry: entry }),
      addLogEntry: async (entry: LogEntryCreate) => {
        const record = await API.graphql({
          query: createLogEntry,
          variables: { input: entry },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        set({
          logEntries: [
            ...get().logEntries,
            { ...entry, createdAt: record.data.createLogEntry.createdAt },
          ],
        });
      },
      setTasks: (tasks: string[]) => set({ tasks }),
      setPositiveOutcomes: (positiveOutcomes: string[]) =>
        set({ positiveOutcomes }),
      setNegativeOutcomes: (negativeOutcomes: string[]) =>
        set({ negativeOutcomes }),
      setExcuses: (excuses: string[]) => set({ excuses }),
      setSensations: (sensations: string[]) => set({ sensations }),
      setDistractions: (distractions: string[]) => set({ distractions }),
      setInsight: (insight: string) => set({ insight }),
      setUser: (user: any) => set({ user }),
      fetch: async () => {
        const [query1, query2, query3] = await Promise.all([
          API.graphql({
            query: listLogEntries,
            authMode: "AMAZON_COGNITO_USER_POOLS",
          }),
          await API.graphql({
            query: listModuleCompletions,
            authMode: "AMAZON_COGNITO_USER_POOLS",
          }),
          await API.graphql({
            query: listCoreBeliefs,
            authMode: "AMAZON_COGNITO_USER_POOLS",
          }),
        ]);

        if (query1.data.listLogEntries) {
          set({ logEntries: query1.data.listLogEntries?.items });
        }
        if (query2.data.listModuleCompletions) {
          set({ moduleCompletions: query2.data.listModuleCompletions?.items });
        }
        if (query3.data.listCoreBeliefs) {
          const all = query3.data.listCoreBeliefs?.items;
          const last = all[all.length - 1];
          set({ coreBeliefs: last });
        }
        set({ loading: false });
      },
    }),
    {
      name: "app-state",
      getStorage: () => AsyncStorage,
      partialize: (state) => ({
        tasks: state.tasks,
        excuses: state.excuses,
        sensations: state.sensations,
        distractions: state.distractions,
        positiveOutcomes: state.positiveOutcomes,
        negativeOutcomes: state.negativeOutcomes,
        readinessForChange: state.readinessForChange,
      }),
    }
  )
);
