import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  Checkbox,
  ScrollView,
  Image,
} from "native-base";
import image from "../../assets/cycle.jpg";

function Cycle({ navigation, route }) {
  return (
    <ScrollView>
      <Center py="3">
        <Container py="3">
          <VStack space="3">
            <Heading>Your complete procrastination cycle</Heading>
            <Text>
              Over the last 3 weeks you have built a better understanding of the
              psychological mechanisms driving your procrastination.
            </Text>
            <Image
              source={image}
              height={320}
              width={320}
              alt="Procrastination reinforcement loop"
            />
            <Text>
              To make long term change you need to be intimately familiar with
              your own cycle.
            </Text>
            <Text>
              You can review this cycle at any time on your insights page. As
              you record daily reflections, it will populate with new insights
              about your procrastination habits.
            </Text>
            <Button
              variant="outline"
              onPress={() => {
                navigation.push("Change", {
                  screen: "Break",
                });
              }}
            >
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Cycle;
