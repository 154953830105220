import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  Checkbox,
  ScrollView,
  Image,
} from "native-base";
import image from "../../assets/cycle.jpg";

function Welcome({ navigation, route }) {
  return (
    <ScrollView h={800}>
      <Center>
        <Container py="3">
          <VStack space="3">
            <Heading>Welcome to lesson 5 👋</Heading>
            <Text>
              In this exercise you’ll learn one of the most effective strategies
              for overcoming procrastination
            </Text>
            <Text>
              We are starting right at the beginning of the procrastination
              cycle 👇 by modifying the task itself!{" "}
            </Text>
            <Image source={image} height={320} width={320} />
            <Button
              variant="outline"
              onPress={() => {
                navigation.push("DefiningAchievableTasks", {
                  screen: "WhatTasks",
                });
              }}
            >
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Welcome;
