import "react-native-gesture-handler";
import * as React from "react";
import { Platform } from "react-native";
import { useColorScheme } from "react-native";
import { DefaultTheme, DarkTheme } from "@react-navigation/native";
import {
  Box,
  Heading,
  Button,
  Center,
  Container,
  Text,
  HStack,
  Menu,
  extendTheme,
  Image,
  Spinner,
  Pressable,
} from "native-base";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { NativeBaseProvider } from "native-base";
import HomeScreen from "./screens/Home";
import { useStore } from "./state";
import { Amplify, Auth, Hub } from "aws-amplify";
import awsConfig from "./aws-exports";
import Reflect from "./screens/Reflect";
import LogScreen from "./screens/Log";
import UnderstandingProcrastination from "./screens/UnderstandingProcrastination";
import DeleteAccount from "./screens/DeleteAccount";
import Beliefs from "./screens/Beliefs";
import Journey from "./screens/Journey";
import Onboarding from "./screens/Onboarding";
import Insights from "./screens/Insights";
import DefiningAchievableTasks from "./screens/DefiningAchievableTasks";
import image from "./assets/welcome.png";
import { init, track } from "@amplitude/analytics-react-native";
import { Linking } from "react-native";
import * as WebBrowser from "expo-web-browser";
import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import { API, graphqlOperation } from "aws-amplify";
import { listNotificationDevices } from "./graphql/queries";
import { createNotificationDevice } from "./graphql/mutations";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { Octicons } from "@expo/vector-icons";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { ScrollView } from "react-native-gesture-handler";
import ReinforcementFactors from "./screens/ReinforcementFactors";
import Change from "./screens/Change";

if (Platform.OS === "ios") {
  // Polyfills required to use Intl with Hermes engine
  require("@formatjs/intl-getcanonicallocales/polyfill").default;
  require("@formatjs/intl-locale/polyfill").default;
  require("@formatjs/intl-pluralrules/polyfill").default;
  require("@formatjs/intl-pluralrules/locale-data/en").default;
  require("@formatjs/intl-numberformat/polyfill").default;
  require("@formatjs/intl-numberformat/locale-data/en").default;
  require("@formatjs/intl-datetimeformat/polyfill").default;
  require("@formatjs/intl-datetimeformat/locale-data/en").default;
  require("@formatjs/intl-datetimeformat/add-all-tz").default;
}
[];

const isLocalhost = false;
const isMobile = false;

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    domain: "auth.procrastinationapp.com",
    redirectSignIn: isLocalhost
      ? isMobile
        ? "exp://172.20.10.3:19000/--/"
        : "http://localhost:19006/"
      : isMobile
      ? "procrastinationapp://"
      : "https://app.procrastinationapp.com/",
    redirectSignOut: isLocalhost
      ? isMobile
        ? "exp://172.20.10.3:19000/--/"
        : "http://localhost:19006/"
      : isMobile
      ? "procrastinationapp://"
      : "https://app.procrastinationapp.com/",
    urlOpener: async (url, redirectUrl) => {
      console.log(redirectUrl);
      // On Expo, use WebBrowser.openAuthSessionAsync to open the Hosted UI pages.
      const { type, url: newUrl } = await WebBrowser.openAuthSessionAsync(
        url,
        redirectUrl
      );

      if (type === "success") {
        await WebBrowser.dismissBrowser();

        if (Platform.OS === "ios") {
          return Linking.openURL(newUrl);
        }
      }
    },
  },
};

Amplify.configure(updatedAwsConfig);

const Stack = createNativeStackNavigator();
const Tab = createBottomTabNavigator();

const theme = extendTheme({
  config: {
    useSystemColorMode: true,
  },
  fontSizes: {
    "2xs": 14,
    xs: 16,
    sm: 18,
    md: 20,
    lg: 22,
    xl: 24,
    "2xl": 28,
    "3xl": 34,
    "4xl": 40,
    "5xl": 52,
    "6xl": 64,
    "7xl": 76,
    "8xl": 100,
    "9xl": 132,
  },
  components: {
    Container: {
      defaultProps: {
        maxWidth: "85%",
      },
    },
    Checkbox: {
      baseStyle: ({ colorMode }) => ({
        _stack: {
          borderWidth: "1",
          borderColor: colorMode === "dark" ? "coolGray.900" : "coolGray.200",
          backgroundColor:
            colorMode === "dark" ? "coolGray.900" : "coolGray.200",
          rounded: "8",
          mb: "3",
          p: 3,
          w: "100%",
        },
        _text: {
          flex: 1,
        },
        _checked: {
          _stack: {
            backgroundColor:
              colorMode === "dark" ? "coolGray.800" : "coolGray.300",
          },
        },
      }),
    },
  },
});

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

async function registerForPushNotificationsAsync() {
  let token;

  if (Platform.OS === "android") {
    Notifications.setNotificationChannelAsync("default", {
      name: "default",
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: "#FF231F7C",
    });
  }

  if (Device.isDevice) {
    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== "granted") {
      //alert('Failed to get push token for push notification!');
      return;
    }
    token = (await Notifications.getExpoPushTokenAsync()).data;
    //console.log(token);
  } else {
    //alert('Must use physical device for Push Notifications');
  }

  return token;
}

function App() {
  const user = useStore((state) => state.user);
  const setUser = useStore((state) => state.setUser);
  const fetchData = useStore((state) => state.fetch);
  const loading = useStore((state) => state.loading);
  const setLoading = useStore((state) => state.setLoading);
  const moduleCompletions = useStore((state) => state.moduleCompletions);
  const [expoPushToken, setExpoPushToken] = React.useState("");
  const [notification, setNotification] = React.useState(false);
  const notificationListener = React.useRef();
  const responseListener = React.useRef();

  React.useEffect(() => {
    init("c32400717989dc0ce23d4abc89328f13");

    if (user === null) {
      track("Welcome Page Viewed");
    }

    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          setLoading(true);
          setUser(data);
          track("User Logged In");
          fetchData();
          console.log("user signed in");
          break;
        case "signUp":
          console.log("user signed up");
          break;
        case "signOut":
          console.log("user signed out");
          setUser(null);
          break;
        case "signIn_failure":
          console.log("user sign in failed");
          break;
        case "configured":
          console.log("the Auth module is configured");
      }
    });

    Auth.currentAuthenticatedUser()
      .then((userData) => {
        setUser(userData);
      })
      .then(() => {
        fetchData();
      })
      .catch((error) => {
        setUser(null);
        setLoading(false);
      });

    registerForPushNotificationsAsync().then(async (token) => {
      if (token) {
        setExpoPushToken(token);

        const query = await API.graphql({
          query: listNotificationDevices,
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        if (query.data.listNotificationDevices?.items?.length < 1) {
          await API.graphql({
            query: createNotificationDevice,
            variables: {
              input: { token, timezone: new Date().getTimezoneOffset() / 60 },
            },
            authMode: "AMAZON_COGNITO_USER_POOLS",
          });
        }
      }
    });

    // This listener is fired whenever a notification is received while the app is foregrounded
    notificationListener.current =
      Notifications.addNotificationReceivedListener((notification) => {
        setNotification(notification);
      });

    // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
    responseListener.current =
      Notifications.addNotificationResponseReceivedListener((response) => {
        console.log(response);
      });

    return () => {
      Notifications.removeNotificationSubscription(
        notificationListener.current
      );
      Notifications.removeNotificationSubscription(responseListener.current);
    };
  }, []);

  const scheme = useColorScheme();

  if (loading) {
    return (
      <NativeBaseProvider theme={theme}>
        <Box
          justifyContent="center"
          background={scheme === "dark" ? "black" : "white"}
          height="full"
        >
          <Center>
            <Container>
              <HStack mt="8" space={2} justifyContent="center">
                <Spinner accessibilityLabel="Loading progress" />
                <Heading size="md">Loading your progress</Heading>
              </HStack>
            </Container>
          </Center>
        </Box>
      </NativeBaseProvider>
    );
  }

  if (user === null) {
    return (
      <NativeBaseProvider theme={theme}>
        <Box background={scheme === "dark" ? "black" : "white"} height="full">
          <ScrollView>
            <Center w="full">
              <Text my={16} bold>
                Procrastination App
              </Text>
              <Container w="full">
                <Image
                  source={image}
                  width="300"
                  height="345"
                  alt="Procrastination App"
                  mb="12"
                  alignSelf="center"
                />
                <Text fontSize="xl" bold>
                  Welcome!
                </Text>
                <Text mb="8">
                  We’re here to help you achieve life’s most important goals
                </Text>
                <Button w="full" onPress={() => Auth.federatedSignIn()}>
                  Get started
                </Button>
              </Container>
            </Center>
          </ScrollView>
        </Box>
      </NativeBaseProvider>
    );
  }

  return (
    <NativeBaseProvider theme={theme}>
      <NavigationContainer theme={scheme === "dark" ? DarkTheme : DefaultTheme}>
        <Stack.Navigator
          initialRouteName={
            moduleCompletions.find((m) => m.number === 0)
              ? "Main"
              : "Onboarding"
          }
          screenOptions={{ headerShown: false }}
        >
          <Stack.Screen name="Main" component={Main} />
          <Stack.Screen name="Onboarding" component={Onboarding} />
        </Stack.Navigator>
      </NavigationContainer>
    </NativeBaseProvider>
  );
}

const Main = ({}) => (
  <Tab.Navigator
    initialRouteName="Today"
    screenOptions={({ route }) => ({
      tabBarIcon: ({ color, size }) => {
        switch (route.name) {
          case "Today":
            return <Octicons name="home" size={size} color={color} />;
          case "Insights":
            return <Octicons name="light-bulb" size={size} color={color} />;
          default:
            return <Octicons name="book" size={size} color={color} />;
        }
      },
    })}
  >
    <Tab.Screen
      name="Today"
      component={Today}
      options={{ headerShown: false }}
    />
    <Tab.Screen
      name="Reflections"
      component={LogScreen}
      options={{ headerShown: false }}
    />
    <Tab.Screen
      name="Insights"
      component={Insights}
      options={{ headerShown: false }}
    />
  </Tab.Navigator>
);

const Today = ({ navigation }) => {
  const signout = React.useCallback(() => {
    Auth.signOut();
  }, []);
  const scheme = useColorScheme();

  return (
    <Stack.Navigator
      initialRouteName="Journey"
      screenOptions={{
        headerBackTitle: "Back",
        headerRight: () => (
          <Menu
            w="190"
            trigger={(triggerProps) => {
              return (
                <Pressable
                  accessibilityLabel="More options menu"
                  {...triggerProps}
                >
                  <MaterialCommunityIcons
                    name="account"
                    size={24}
                    color={scheme === "dark" ? "white" : "black"}
                  />
                </Pressable>
              );
            }}
          >
            <Menu.Item onPress={signout}>Sign out</Menu.Item>
            <Menu.Item
              onPress={() =>
                navigation.reset({
                  index: 0,
                  routes: [{ name: "DeleteAccount" }],
                })
              }
            >
              Delete account
            </Menu.Item>
          </Menu>
        ),
      }}
    >
      <Stack.Screen
        name="Home"
        component={HomeScreen}
        options={{
          title: "Procrastination App",
          headerBackTitle: "Journey",
        }}
      />
      <Stack.Screen
        name="Reflect"
        component={Reflect}
        options={{ title: "Daily Reflection" }}
      />
      <Stack.Screen
        name="LogScreen"
        component={LogScreen}
        options={{ title: "Log", headerShown: false }}
      />
      <Stack.Screen
        name="UnderstandingProcrastination"
        component={UnderstandingProcrastination}
        options={{ title: "Lesson One" }}
      />
      <Stack.Screen
        name="Beliefs"
        component={Beliefs}
        options={{ title: "Lesson Two" }}
      />
      <Stack.Screen
        name="ReinforcementFactors"
        component={ReinforcementFactors}
        options={{ title: "Lesson Three" }}
      />
      <Stack.Screen
        name="Change"
        component={Change}
        options={{ title: "Lesson Four" }}
      />
      <Stack.Screen
        name="DefiningAchievableTasks"
        component={DefiningAchievableTasks}
        options={{ title: "Lesson Five" }}
      />
      <Stack.Screen
        name="DeleteAccount"
        component={DeleteAccount}
        options={{ title: "Delete Account" }}
      />
      <Stack.Screen
        name="Journey"
        component={Journey}
        options={{ title: "Procrastination App" }}
      />
    </Stack.Navigator>
  );
};

export default App;
