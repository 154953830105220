export const coreBeliefDescription = {
  "Need for control":
    "Procrastinating this task helps you feel like you’re in control because you’re doing things on your own terms.",
  "Need for pleasure":
    "You’re procrastinating this task to alleviate boredom and frustration. It allows you to seek out more immediate pleasure.",
  "Fear of judgement":
    "Procrastinating this task is a form of self-protection. By putting it off you avoid the risk of failure or disapproval.",
  "Fear of uncertainty":
    "By putting off this task you avoid the unknown or any catastrophic outcomes. Taking no action ensures nothing changes, and if nothing changes, nothing bad can happen. It temporarily alleviates your fears.",
  "Lack of self-confidence":
    "Procrastinating this task is a way of protecting yourself. You might be avoiding a challenging situation that will test your capabilities. By putting it off and not trying you avoid having to face your supposed flaws.",
  "Intolerance of low energy":
    "You’re procrastinating in the hope it will rebuild your energy and get rid of any exhaustion. You assume that if you rest rather than do, things will somehow get better.",
};

export const coreBeliefStatement = {
  "Need for control":
    "You need to call the shots and shouldn’t have to do things you don’t want to.",
  "Need for pleasure":
    "Fun should always come first, life is too short to be doing things that are boring or hard.",
  "Fear of judgement":
    "You must do things perfectly, otherwise you will fail or others will think badly of you.",
  "Fear of uncertainty":
    "You must be certain of what lies ahead. You are better off not doing anything incase something bad happens.",
  "Lack of self-confidence":
    "You can’t do it. You are just too incapable and inadequate.",
  "Intolerance of low energy":
    "You can’t do things when you are stressed, fatigued or unmotivated.",
};
