export enum Categories {
    NeedForPleasure = "Need for pleasure,",
    FearOfJudgement = "Fear of judgement,",
    FearOfUncertainty = "Fear of uncertainty,",
    LackOfSelfConfidence = "Lack of self-confidence,",
    IntoleranceOfLowEnergy = "Intolerance of low energy,",
    NeedForControl = "Need for control,",
}

export const questions = [
    {
        category: Categories.NeedForPleasure,
        title: "Life is too short to be doing things that are boring or hard"
    },
    {
        category: Categories.FearOfJudgement,
        title: "I can’t have others think poorly of me"
    },
    {
        category: Categories.FearOfUncertainty,
        title: "I am better off not doing anything, than risk it going bad"
    },
    {
        category: Categories.LackOfSelfConfidence,
        title: "If I know I won’t be able to do something, then I shouldn’t bother"
    },
    {
        category: Categories.IntoleranceOfLowEnergy,
        title: "I must rest when my energy is low"
    },
    {
        category: Categories.NeedForControl,
        title: "I shouldn’t have to do things that I don’t want to"
    },
    {
        category: Categories.NeedForPleasure,
        title: "Pleasure here and now should be all that matters"
    },
    {
        category: Categories.FearOfJudgement,
        title: "If I put my work out there, then others will think badly of me"
    },
    {
        category: Categories.FearOfJudgement,
        title: "I can’t stand not knowing the outcome"
    },
    {
        category: Categories.LackOfSelfConfidence,
        title: "I shouldn’t try things when I know I’m no good"
    },
    {
        category: Categories.IntoleranceOfLowEnergy,
        title: "If I do things when I am stressed / fatigued / unmotivated / depressed, I will make things worse"
    },
    {
        category: Categories.NeedForControl,
        title: "Things should be done my way"
    },
    {
        category: Categories.NeedForPleasure,
        title: "Life should be fun at all times"
    },
    {
        category: Categories.FearOfJudgement,
        title: "I must do things perfectly"
    },
    {
        category: Categories.FearOfUncertainty,
        title: "If I take action, then something bad will happen"
    },
    {
        category: Categories.LackOfSelfConfidence,
        title: "If I try things, then my inadequacies will show through"
    },
    {
        category: Categories.IntoleranceOfLowEnergy,
        title: "I can’t do things when I am stressed / fatigued / unmotivated / depressed"
    },
    {
        category: Categories.NeedForControl,
        title: "I shouldn’t have to do things because someone else says so"
    },
    {
        category: Categories.NeedForPleasure,
        title: "Fun should always come first"
    },
    {
        category: Categories.FearOfJudgement,
        title: "If I try, then I will only fail"
    },
    {
        category: Categories.FearOfUncertainty,
        title: "I must be certain of what will happen"
    },
    {
        category: Categories.LackOfSelfConfidence,
        title: "I can’t do things because I am incapable"
    },
    {
        category: Categories.IntoleranceOfLowEnergy,
        title: "I must be energised to be able to do things"
    },
    {
        category: Categories.NeedForControl,
        title: "If I am not 100% in charge of what I do, then I am weak"
    },
    {
        category: Categories.NeedForPleasure,
        title: "If I pass on having fun, then I will become a boring lifeless drone"
    },
    {
        category: Categories.FearOfJudgement,
        title: "I must not fail"
    },
    {
        category: Categories.FearOfUncertainty,
        title: "I should be prepared for the worst"
    },
    {
        category: Categories.LackOfSelfConfidence,
        title: "I’m too inadequate so I can’t get things done"
    },
    {
        category: Categories.IntoleranceOfLowEnergy,
        title: "If I do things when I am not energised, then it won’t work out"
    },
    {
        category: Categories.NeedForControl,
        title: "I must be in charge at all times"
    },
]