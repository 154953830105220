/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getNotificationDevice = /* GraphQL */ `
  query GetNotificationDevice($id: ID!) {
    getNotificationDevice(id: $id) {
      id
      token
      timezone
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listNotificationDevices = /* GraphQL */ `
  query ListNotificationDevices(
    $filter: ModelNotificationDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationDevices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        token
        timezone
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncNotificationDevices = /* GraphQL */ `
  query SyncNotificationDevices(
    $filter: ModelNotificationDeviceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotificationDevices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        token
        timezone
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const deviceByTimezone = /* GraphQL */ `
  query DeviceByTimezone(
    $timezone: String!
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deviceByTimezone(
      timezone: $timezone
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        token
        timezone
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getAccountDeletion = /* GraphQL */ `
  query GetAccountDeletion($id: ID!) {
    getAccountDeletion(id: $id) {
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listAccountDeletions = /* GraphQL */ `
  query ListAccountDeletions(
    $filter: ModelAccountDeletionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountDeletions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAccountDeletions = /* GraphQL */ `
  query SyncAccountDeletions(
    $filter: ModelAccountDeletionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAccountDeletions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getFeedback = /* GraphQL */ `
  query GetFeedback($id: ID!) {
    getFeedback(id: $id) {
      id
      rating
      liked
      improvements
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listFeedbacks = /* GraphQL */ `
  query ListFeedbacks(
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeedbacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        rating
        liked
        improvements
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFeedbacks = /* GraphQL */ `
  query SyncFeedbacks(
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFeedbacks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        rating
        liked
        improvements
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getModuleCompletion = /* GraphQL */ `
  query GetModuleCompletion($id: ID!) {
    getModuleCompletion(id: $id) {
      id
      number
      insight
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listModuleCompletions = /* GraphQL */ `
  query ListModuleCompletions(
    $filter: ModelModuleCompletionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listModuleCompletions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        insight
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncModuleCompletions = /* GraphQL */ `
  query SyncModuleCompletions(
    $filter: ModelModuleCompletionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncModuleCompletions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        number
        insight
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const moduleCompletionsByOwner = /* GraphQL */ `
  query ModuleCompletionsByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelModuleCompletionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    moduleCompletionsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        insight
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getLogEntry = /* GraphQL */ `
  query GetLogEntry($id: ID!) {
    getLogEntry(id: $id) {
      id
      task
      excuse
      sensation
      reflection
      belief
      positiveOutcome
      negativeOutcome
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listLogEntries = /* GraphQL */ `
  query ListLogEntries(
    $filter: ModelLogEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLogEntries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        task
        excuse
        sensation
        reflection
        belief
        positiveOutcome
        negativeOutcome
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLogEntries = /* GraphQL */ `
  query SyncLogEntries(
    $filter: ModelLogEntryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLogEntries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        task
        excuse
        sensation
        reflection
        belief
        positiveOutcome
        negativeOutcome
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const logEntriesByOwner = /* GraphQL */ `
  query LogEntriesByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelLogEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    logEntriesByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        task
        excuse
        sensation
        reflection
        belief
        positiveOutcome
        negativeOutcome
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCoreBeliefs = /* GraphQL */ `
  query GetCoreBeliefs($id: ID!) {
    getCoreBeliefs(id: $id) {
      id
      needToBeInCharge
      pleasureSeeking
      fearOfFailure
      fearOfUncertainty
      lowSelfConfidence
      depletedEnergy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listCoreBeliefs = /* GraphQL */ `
  query ListCoreBeliefs(
    $filter: ModelCoreBeliefsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoreBeliefs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        needToBeInCharge
        pleasureSeeking
        fearOfFailure
        fearOfUncertainty
        lowSelfConfidence
        depletedEnergy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCoreBeliefs = /* GraphQL */ `
  query SyncCoreBeliefs(
    $filter: ModelCoreBeliefsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCoreBeliefs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        needToBeInCharge
        pleasureSeeking
        fearOfFailure
        fearOfUncertainty
        lowSelfConfidence
        depletedEnergy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
