import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  Checkbox,
  ScrollView,
  Image,
} from "native-base";

function FirstStep({ navigation, route }) {
  return (
        <ScrollView h={800}>
    <Center>
      <Container py="3">
          <VStack space="3">
            <Heading>The first step towards overcoming procrastination</Heading>
            <Text>The crazy thing about the procrastination reinforcement loop is how quick it happens. We usually go through the entire loop in a fraction of a second! Because it happens so quickly, it makes it very hard to change.</Text>
            <Text>The first step in overcoming procrastination is understanding your own procrastination reinforcement loop! We mean really understanding it! You might think you know yourself, but chances are you haven’t even scratched the surface.</Text>
            <Text>We are going to scratch that surface with a simple exercise called procrastination logging.</Text>
            <Button
            variant="outline"
              onPress={() => {
                navigation.push("UnderstandingProcrastination", { screen: "Mission" });
              }}
            >
              Continue
            </Button>
          </VStack>
      </Container>
    </Center>
        </ScrollView>
  );
}

export default FirstStep;
