import * as React from "react";
import {
  Text,
  Heading,
  Button,
  Center,
  Container,
  TextArea,
  VStack,
  ScrollView,
  KeyboardAvoidingView,
} from "native-base";
import { useStore } from "../../state";

function What({ navigation, route }) {
  const moduleCompletions = useStore((state) => state.moduleCompletions);
  const newLogEntry = useStore((state) => state.newLogEntry);
  const setNewLogEntry = useStore((state) => state.setNewLogEntry);
  const tasks = useStore((state) => state.tasks);
  const setTasks = useStore((state) => state.setTasks);
  const [newTask, setNewTask] = React.useState("");
  const [showInputField, setShowInputField] = React.useState(false);
  const complete = (task: string, isCustom?: boolean) => {
    setNewLogEntry({ ...newLogEntry, task });
    if (isCustom) {
      setTasks([...tasks, task]);
    }
    if (moduleCompletions.find((m) => m.number === 3)) {
      navigation.push("Reflect", { screen: "Sensation" });
      return;
    }
    moduleCompletions.find((m) => m.number === 2)
      ? navigation.push("Reflect", { screen: "Beliefs" })
      : navigation.push("Reflect", { screen: "Sensation" });
  };
  return (
    <ScrollView>
      <Center py="3">
        <Container py="3" w="full">
          <VStack space="3" w="full">
            <Heading size="lg">What are you procrastinating?</Heading>
            <Text>
              Select one of the common tasks you identified, or add a new one.
            </Text>
            {showInputField ? (
              <KeyboardAvoidingView behavior="padding">
                <VStack space="3">
                  <TextArea
                    placeholder="Describe the task..."
                    autoCompleteType="text"
                    value={newTask}
                    onChangeText={setNewTask}
                    maxW="500"
                  />
                  <Button onPress={() => complete(newTask, true)}>
                    Submit
                  </Button>
                  <Button
                    variant="outline"
                    onPress={() => setShowInputField(false)}
                  >
                    Cancel
                  </Button>
                </VStack>
              </KeyboardAvoidingView>
            ) : (
              <>
                {tasks
                  .filter((task) => task)
                  .map((task) => (
                    <Button key={task} onPress={() => complete(task)}>
                      {task}
                    </Button>
                  ))}
                <Button onPress={() => setShowInputField(true)}>
                  {tasks.length > 0 ? "Other task +" : "Add a task +"}
                </Button>
              </>
            )}
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default What;
