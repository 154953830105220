import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  Checkbox,
  ScrollView,
  Image,
} from "native-base";

function Habit({ navigation, route }) {
  return (
    <ScrollView h={800}>
      <Center>
        <Container py="3">
          <VStack space="3">
            <Heading>Creating the habit</Heading>
            <Text>
              At this point you might be thinking, I know all of this! If that
              is the case you have demonstrated the difference between knowledge
              and habits! What is far more important than knowing this advice is
              creating the habit of using it.
            </Text>
            <Text>
              This week we are going to help you create the habit of setting
              goals that are less likely to result in procrastination. We’re
              going to do that by building it into your daily reflection.
            </Text>
            <Button
              variant="outline"
              onPress={() => {
                navigation.push("DefiningAchievableTasks", {
                  screen: "Mission",
                });
              }}
            >
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Habit;
