import * as React from "react";
import {
  Checkbox,
  Heading,
  Button,
  Center,
  Container,
  TextArea,
  Text,
  VStack,
  ScrollView,
} from "native-base";
import { useStore } from "../../state";

function Modify({ navigation, route }) {
  const newLogEntry = useStore((state) => state.newLogEntry);
  const setNewLogEntry = useStore((state) => state.setNewLogEntry);
  const tasks = useStore((state) => state.tasks);
  const setTasks = useStore((state) => state.setTasks);
  const [newTask, setNewTask] = React.useState(newLogEntry.task);
  const [checklist, setChecklist] = React.useState([]);
  const complete = (task: string) => {
    setTasks([...tasks.filter((t) => t !== newLogEntry.task), task]);
    //setNewLogEntry({ ...newLogEntry, task });
    navigation.push("Reflect", { screen: "Review", params: { newTask: task } });
  };
  return (
    <ScrollView>
      <Center>
        <Container py="3">
          <VStack space="3">
            <Heading size="lg" mt="3">
              Try modifying your task ✏️
            </Heading>
            <Text>
              You're procrastinating{" "}
              <Text highlight bold color="black">
                {newLogEntry.task}
              </Text>{" "}
              because it’s making you feel{" "}
              <Text highlight bold color="black">
                {newLogEntry.sensation}
              </Text>
              .
            </Text>
            <Text>
              Try modifying the task so that you feel more like getting started:
            </Text>
            <TextArea
              placeholder={newLogEntry.task}
              autoCompleteType="text"
              defaultValue={newLogEntry.task}
              onChangeText={setNewTask}
              maxW="500"
            />
            <Text>My task or goal is:</Text>
            <Checkbox.Group
              defaultValue={[]}
              accessibilityLabel="My task or goal is:"
              onChange={(values) => {
                setChecklist(values || []);
              }}
            >
              {[
                "Specific (ie. it describes the next specific action I need to take in order to move closer to completion)",
                "Small enough that I can cross the “starting threshold”",
              ].map((item) => (
                <Checkbox key={`checklist-${item}`} value={item}>
                  {item}
                </Checkbox>
              ))}
            </Checkbox.Group>
            <Button
              isDisabled={checklist.length < 2}
              onPress={() => complete(newTask)}
            >
              Next
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Modify;
