import * as React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import Cycle from "./1Cycle";
import Break from "./2Break";
import FourPoints from "./3FourPoints";
import Increasing from "./4Increasing";
import Assess from "./5Assess";
import Readiness from "./6Readiness";
import Commitment from "./7Commitment";
import Ability from "./MotivationalFactors/Ability";
import Willingness from "./MotivationalFactors/Willingness";
import Worthwhile from "./MotivationalFactors/Worthwhile";
import { questions } from "./questions";
const Stack = createNativeStackNavigator();
import { useStore } from "../../state";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  ScrollView,
} from "native-base";

const Question = ({ navigation, route }) => {
  const responses = useStore((state) => state.quizResponses);
  const setResponses = useStore((state) => state.setQuizResponses);
  const undoResponse = useStore((state) => state.undoQuizResponse);
  const setReadinessForChange = useStore(
    (state) => state.setReadinessForChange
  );
  const { question, index } = route.params;
  const submit = (score: number) => {
    setResponses({
      category: question.category,
      score: question.reverse ? 5 - score : score,
    });
    if (index === questions.length - 1) {
      setReadinessForChange(responses);
      navigation.push("Change", {
        screen: "Readiness",
      });
    } else {
      navigation.push("Change", {
        screen: questions[index + 1].title,
      });
    }
  };

  React.useEffect(
    () =>
      navigation.addListener("beforeRemove", () => {
        undoResponse();
      }),
    [navigation]
  );

  return (
    <>
      <ScrollView>
        <Center>
          <Container py="3" w="full">
            <VStack space="3" w="full">
              <Heading mt="3">{question.title}</Heading>
              <Text>How much is this like you?</Text>
              <Button onPress={() => submit(5)}>Strongly agree</Button>
              <Button onPress={() => submit(4)}>Agree</Button>
              <Button onPress={() => submit(3)}>
                Neither agree nor disagree
              </Button>
              <Button onPress={() => submit(2)}>Disagree</Button>
              <Button onPress={() => submit(1)}>Strongly disagree</Button>
              <Text fontSize="14">
                Question {index + 1} of {questions.length}
              </Text>
            </VStack>
          </Container>
        </Center>
      </ScrollView>
    </>
  );
};

function Change() {
  return (
    <Stack.Navigator
      initialRouteName="Cycle"
      screenOptions={{
        headerShown: false,
        title: "Exercise Four",
      }}
    >
      <Stack.Screen name="Cycle" component={Cycle} />
      <Stack.Screen name="Break" component={Break} />
      <Stack.Screen name="FourPoints" component={FourPoints} />
      <Stack.Screen name="Increasing" component={Increasing} />
      <Stack.Screen name="Assess" component={Assess} />
      <Stack.Screen name="Readiness" component={Readiness} />
      <Stack.Screen name="Commitment" component={Commitment} />
      <Stack.Screen name="Ability" component={Ability} />
      <Stack.Screen name="Willingness" component={Willingness} />
      <Stack.Screen name="Worthwhile" component={Worthwhile} />
      {questions.map((question, index) => (
        <Stack.Screen
          key={question.title}
          name={question.title}
          component={Question}
          initialParams={{ question, index }}
        />
      ))}
    </Stack.Navigator>
  );
}

export default Change;
