import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  Checkbox,
  ScrollView,
  Image,
} from "native-base";

function Specific({ navigation, route }) {
  return (
    <ScrollView h={800}>
      <Center>
        <Container py="3">
          <VStack space="3">
            <Heading>Specific tasks are less overwhelming</Heading>
            <Text>
              When a task is both specific and small we can easily imagine what
              success looks like. That clarity is motivating!
            </Text>
            <Text>
              Here are a few examples for how we can more clearly define those
              tasks:
            </Text>
            <Text>• Walk home from work rather than drive on Monday.</Text>
            <Text>
              • Open the document and write a 5 bullet point overview of my
              essay.
            </Text>
            <Text>
              The new tasks took something big and nebulous and broke it into a
              small first step. This is a critically important skill.
            </Text>
            <Button
              variant="outline"
              onPress={() => {
                navigation.push("DefiningAchievableTasks", {
                  screen: "BreakingUp",
                });
              }}
            >
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Specific;
