import * as React from "react";
import {
  Checkbox,
  Heading,
  Button,
  Center,
  Container,
  VStack,
  Text,
  ScrollView,
  KeyboardAvoidingView,
  TextArea,
} from "native-base";
import { useStore } from "../../state";

function NegativeOutcome({ navigation, route }) {
  const newLogEntry = useStore((state) => state.newLogEntry);
  const setNewLogEntry = useStore((state) => state.setNewLogEntry);
  const negativeOutcomes = useStore((state) => state.negativeOutcomes);
  const setNegativeOutcomes = useStore((state) => state.setNegativeOutcomes);
  const [showInputField, setShowInputField] = React.useState(false)
  const [newOutcome, setNewOutcome] = React.useState("")
  const complete = (negativeOutcome: string, isCustom?: boolean) => {
    setNewLogEntry({ ...newLogEntry, negativeOutcome });
    if (isCustom) {
      setNegativeOutcomes([...negativeOutcomes, negativeOutcome]);
    }
    navigation.push("Reflect", { screen: "Review" });
  };
  return (
    <ScrollView h={800}>
      <Center>
        <Container py="3">
          <VStack space="3">
            <Heading size="lg" mt="3">
              What is a negative outcome of putting off the task?
            </Heading>
            <Text>Select from the common outcomes or add your own.</Text>
            {showInputField ? (
              <KeyboardAvoidingView behavior="padding">
                <VStack space="3">
                  <TextArea
                    placeholder="Describe the negative outcome..."
                    autoCompleteType="text"
                    value={newOutcome}
                    onChangeText={setNewOutcome}
                    maxW="500"
                  />
                  <Button onPress={() => complete(newOutcome, true)}>
                    Submit
                  </Button>
                  <Button
                    variant="outline"
                    onPress={() => setShowInputField(false)}
                  >
                    Cancel
                  </Button>
                </VStack>
              </KeyboardAvoidingView>
            ) : (
              <>
                {[
                  ...negativeOutcomes,
                  "The task gets harder and more overwhelming the longer I put it off",
                  "I start criticizing myself and building up pressure on myself",
                  "Incomplete tasks start piling up around me making me feel overwhelmed",
                ]
                  .filter((outcome) => outcome)
                  .map((outcome) => (
                    <Button key={outcome} onPress={() => complete(outcome)}>
                      {outcome}
                    </Button>
                  ))}
                <Button onPress={() => setShowInputField(true)}>
                  Add an outcome +
                </Button>
              </>
            )}
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default NegativeOutcome;