import * as React from "react";
import {
  Box,
  Heading,
  Button,
  Center,
  Container,
  Text,
  TextInput,
  VStack,
  ScrollView,
} from "native-base";
import { useColorScheme } from "react-native";
import { useStore } from "../../../state";

function Ability({ navigation, route }) {
  const readinessForChange = useStore((state) => state.readinessForChange);
  const scheme = useColorScheme();
  return (
    <ScrollView>
      <Center py="3">
        <Container py="3">
          <VStack space="3">
            <Heading>Belief in ability to change</Heading>
            {readinessForChange.ability > 10 ? (
              <>
                <Text>
                  Your results indicate that you believe you’re able to change
                  your behavior. That is fantastic 🎉
                </Text>
              </>
            ) : (
              <Text>
                Your results indicate that you don't believe you’re able to
                change your behavior. You might not be, but we are pretty
                confident you can change
              </Text>
            )}
            <Text>
              By getting to this point, you have already shown the perseverance
              necessary to overcome procrastination. We will teach you the
              skills - all you need to do is keep at it!
            </Text>
            <Text>
              If you're doubting your ability, it can help to think through the
              roadblocks in your way. Try responding to the questions below to
              better prepare yourself.
            </Text>
            <Box
              borderWidth="1"
              borderColor={scheme === "dark" ? "coolGray.600" : "coolGray.300"}
              backgroundColor={
                scheme === "dark" ? "coolGray.600" : "coolGray.300"
              }
              p="5"
              rounded="8"
            >
              <Text mb="3">
                What do you think is the most likely reason you won’t continue
                using Procrastination App and learn to overcome procrastination?
              </Text>
              <Text>
                What is one thing you could say or do to overcome that obstacle?
              </Text>
            </Box>
            <Text>
              Responding to those questions 👆already makes you about 15% more
              likely to succeed - not bad!
            </Text>
            <Text>Keep up the great work 💪</Text>
            <Button
              variant="outline"
              onPress={() => {
                navigation.goBack();
              }}
            >
              Back
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Ability;
