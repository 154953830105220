import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  Checkbox,
  ScrollView,
  Image,
} from "native-base";

function Planning({ navigation, route }) {
  return (
    <ScrollView h={800}>
      <Center>
        <Container py="3">
          <VStack space="3">
            <Heading>Planning as procrastination</Heading>
            <Text>
              The advice of breaking down tasks also comes with a big warning!
              Many procrastinators are actually fantastic planners. The problem
              is that the act of planning is a form of putting off getting
              started.
            </Text>
            <Text>
              Be careful of this trap! If you tend to overplan, only define the
              first one or two steps. You don’t need to define every step in the
              process. Your goal is just getting started.
            </Text>
            <Button
              variant="outline"
              onPress={() => {
                navigation.push("DefiningAchievableTasks", {
                  screen: "Habit",
                });
              }}
            >
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Planning;
