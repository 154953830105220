import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  Checkbox,
  ScrollView,
  Image,
} from "native-base";

function Increasing({ navigation, route }) {
  return (
    <ScrollView>
      <Center py="3">
        <Container py="3">
          <VStack space="3">
            <Heading>
              Increasing your chances of successful behavior change
            </Heading>
            <Text>
              A lot of psychological research has gone into understanding the
              factors that create successful behavior change. One of those
              factors is your motivational state when you start to implement new
              habits.
            </Text>
            <Text>
              Many attempts to change behavior fail because we are simply not
              ready to change.
            </Text>
            <Button
              variant="outline"
              onPress={() => {
                navigation.push("Change", {
                  screen: "Assess",
                });
              }}
            >
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Increasing;
