import * as React from "react";
import { Button } from "react-native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import Welcome from "./Welcome";
import WhatTasks from "./WhatTasks";
import Insights from "./Insights";
import TaskFeatures from "./TaskFeatures";
import Unclear from "./Unclear";
import Specific from "./Specific";
import BreakingUp from "./BreakingUp";
import Planning from "./Planning";
import Habit from "./Habit";
import Mission from "./Mission";
import { useStore } from "../../state";

const Stack = createNativeStackNavigator();

function DefiningAchievableTasks() {
  return (
    <Stack.Navigator
      initialRouteName="Introduction"
      screenOptions={{
        headerShown: false,
        title: "Procrastination App - Exercise Two",
      }}
    >
      <Stack.Screen name="Welcome" component={Welcome} />
      <Stack.Screen name="WhatTasks" component={WhatTasks} />
      <Stack.Screen name="Insights" component={Insights} />
      <Stack.Screen name="TaskFeatures" component={TaskFeatures} />
      <Stack.Screen name="Unclear" component={Unclear} />
      <Stack.Screen name="Specific" component={Specific} />
      <Stack.Screen name="BreakingUp" component={BreakingUp} />
      <Stack.Screen name="Planning" component={Planning} />
      <Stack.Screen name="Habit" component={Habit} />
      <Stack.Screen name="Mission" component={Mission} />
    </Stack.Navigator>
  );
}

export default DefiningAchievableTasks;
