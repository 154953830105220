import * as React from "react";
import { Button } from "react-native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import Introduction from "./Introduction";
import Overcoming from "./Overcoming";
import WhatsToCome from "./WhatsToCome";
import Activities from "./Activities"
import IdentifyBroad from "./IdentifyBroad";
import IdentifySpecific from "./IdentifySpecific";
import Rationalisations from "./Rationalisations"
import IdentifyDistractions from "./IdentifyDistractions";
import IdentifyExcuses from "./IdentifyExcuses";
import Explanation from "./Explanation";
import FirstStep from "./FirstStep";
import Mission from "./Mission";
import { useStore } from "../../state";

const Stack = createNativeStackNavigator();

function UnderstandingProcrastination() {
  return (
    <Stack.Navigator
      initialRouteName="Introduction"
      screenOptions={{
        headerShown: false,
        title: "Procrastination App - Exercise One",
      }}
    >
      <Stack.Screen name="Introduction" component={Introduction} />
      <Stack.Screen name="Overcoming" component={Overcoming} />
      <Stack.Screen
        name="WhatsToCome"
        component={WhatsToCome}
        options={{ headerTitle: "What's to come" }}
      />
      <Stack.Screen
        name="IdentifyBroad"
        component={IdentifyBroad}
        options={{ headerTitle: "Identify broad tasks/goals" }}
      />
      <Stack.Screen
        name="IdentifySpecific"
        component={IdentifySpecific}
        options={{ headerTitle: "Identify specific tasks/goals" }}
      />
      <Stack.Screen
        name="Activities"
        component={Activities}
        options={{ headerTitle: "Procrastination activities" }}
      />
      <Stack.Screen
        name="IdentifyDistractions"
        component={IdentifyDistractions}
        options={{ headerTitle: "What are your procrastination activities?" }}
      />
      <Stack.Screen
        name="Rationalisations"
        component={Rationalisations}
        options={{ headerTitle: "Procrastination rationalisations" }}
      />
      <Stack.Screen
        name="IdentifyExcuses"
        component={IdentifyExcuses}
        options={{ headerTitle: "Identify common excuses" }}
      />
      <Stack.Screen
        name="Explanation"
        component={Explanation}
        options={{ headerTitle: "How procrastination persists! " }}
      />
      <Stack.Screen
        name="FirstStep"
        component={FirstStep}
        options={{
          headerTitle: "The first step towards overcoming procrastination",
        }}
      />
      <Stack.Screen
        name="Mission"
        component={Mission}
        options={{
          headerTitle: "Your mission this week: procrastination logging",
        }}
      />
    </Stack.Navigator>
  );
}

export default UnderstandingProcrastination;
