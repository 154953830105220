import * as React from "react";
import { Platform } from "react-native";
import { API, graphqlOperation } from "aws-amplify";
import {
  Stack,
  Radio,
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  TextArea,
  ScrollView,
  KeyboardAvoidingView,
} from "native-base";
import { useStore } from "../../state";
import { createFeedback } from "../../graphql/mutations";
import ConfettiCannon from "react-native-confetti-cannon";

function Success({ navigation, route }) {
  const logEntries = useStore((state) => state.logEntries);
  const lastCompletedModule = useStore((state) => state.moduleCompletions)
    .map((m) => m.number)
    .sort((a, b) => b - a)[0];
  const [liked, setLiked] = React.useState("");
  const [improvements, setImprovements] = React.useState("");
  const [rating, setRating] = React.useState("");
  const complete = async () => {
    const record = await API.graphql({
      query: createFeedback,
      variables: { input: { rating, liked, improvements } },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    navigation.reset({
      index: 1,
      routes: [
        { name: "Journey" },
        { name: "Home", params: { fireConfettiCannon: true } },
      ],
    });
  };
  return (
    <>
      {logEntries.length === 3 || logEntries.length === 6 ? (
        <ScrollView>
          <KeyboardAvoidingView behavior="padding">
            <Center>
              <Container py="3">
                <VStack space="3">
                  <Heading size="lg" mt="3">
                    Rate your experience!
                  </Heading>
                  <Text>
                    How happy are you with Procrastination App so far?
                  </Text>
                  <Radio.Group
                    name="exampleGroup"
                    defaultValue="3"
                    accessibilityLabel="Pick a rating"
                    onChange={(value) => setRating(value)}
                  >
                    <Stack
                      direction={{
                        base: "row",
                      }}
                      alignItems={{
                        base: "flex-start",
                        md: "center",
                      }}
                      space={4}
                      w="75%"
                      maxW="300px"
                    >
                      <Radio value="1" size="sm" my={1}>
                        😩
                      </Radio>
                      <Radio value="2" size="sm" my={1}>
                        🙁
                      </Radio>
                      <Radio value="3" size="sm" my={1}>
                        😐
                      </Radio>
                      <Radio value="4" size="sm" my={1}>
                        🙂
                      </Radio>
                      <Radio value="5" size="sm" my={1}>
                        😄
                      </Radio>
                    </Stack>
                  </Radio.Group>
                  <Text>What do you like most about it?</Text>
                  <TextArea
                    placeholder="Enter your response here"
                    autoCompleteType="text"
                    value={liked}
                    onChangeText={setLiked}
                  />
                  <Text>How could we improve the experience?</Text>
                  <TextArea
                    placeholder="Enter your response here"
                    autoCompleteType="text"
                    value={improvements}
                    onChangeText={setImprovements}
                  />
                  <Button onPress={() => complete()}>Submit</Button>
                  <Button
                    variant="link"
                    onPress={() =>
                      navigation.navigate("Home", { fireConfettiCannon: true })
                    }
                  >
                    I don't have feedback
                  </Button>
                </VStack>
              </Container>
            </Center>
          </KeyboardAvoidingView>
        </ScrollView>
      ) : (
        <>
          <ScrollView>
            <Center py="3">
              <Container py="3">
                <VStack space="3">
                  <Heading>You've added today's task. Well done 🎉</Heading>
                  <Text>
                    Each day you bring awareness to your procrastination
                    reinforcement loop, you get a little stronger 💪
                  </Text>
                  <Text bold>Come back tomorrow to reflect again</Text>
                  <Button
                    mt={6}
                    onPress={() =>
                      navigation.reset({
                        index: 1,
                        routes: [
                          { name: "Journey" },
                          {
                            name: "Home",
                            params: { exerciseNumber: lastCompletedModule },
                          },
                        ],
                      })
                    }
                  >
                    Finish
                  </Button>
                </VStack>
              </Container>
            </Center>
          </ScrollView>
          <ConfettiCannon
            count={200}
            origin={{ x: -30, y: 200 }}
            autoStart={true}
            explosionSpeed={700}
            fallSpeed={2000}
            fadeOut={true}
          />
        </>
      )}
    </>
  );
}

export default Success;
