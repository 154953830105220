import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  Checkbox,
  ScrollView,
  Image,
} from "native-base";
import { questions } from "./questions"


function Quiz({ navigation, route }) {
  return (
    <ScrollView h={800}>
      <Center py="3">
        <Container py="3">
          <VStack space="3">
            <Heading>
              Identify the unhelpful beliefs driving your procrastination
            </Heading>
            <Text>
              Complete the quiz to identify what’s driving your procrastination.
              Try not to over think them, your initial gut reaction will be your
              best response.{" "}
            </Text>
            <Button
              variant="outline"
              onPress={() => {
                navigation.push("Beliefs", {
                  screen: questions[0].title,
                });
              }}
            >
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Quiz;