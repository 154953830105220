import * as React from "react";
import {
  Box,
  Center,
  Container,
  VStack,
  Heading,
  Text,
  Pressable,
  Badge,
  HStack,
  Spacer,
  Image,
  ScrollView,
} from "native-base";
import { useStore } from "../state";
import { MaterialIcons, MaterialCommunityIcons } from "@expo/vector-icons";
import { useColorScheme } from "react-native";
import image from "../assets/journey.png";
import { track } from "@amplitude/analytics-react-native";

function Journey({ navigation, route }) {
  const moduleCompletions = useStore((state) => state.moduleCompletions);
  const logEntries = useStore((state) => state.logEntries);
  const dates = logEntries.map((entry) =>
    new Date(entry.createdAt).toDateString()
  );
  const scheme = useColorScheme();
  const uniqueDates = [...new Set(dates)];

  React.useEffect(() => {
    track("Journey Page Viewed");
  }, []);

  const renderModule = ({
    onPress,
    exerciseDuration,
    exerciseName,
    complete,
  }) => {
    return (
      <>
        {onPress ? (
          <Pressable onPress={onPress}>
            <Box
              position="absolute"
              w="6"
              h="6"
              rounded="20"
              backgroundColor={
                complete
                  ? scheme === "dark"
                    ? "coolGray.600"
                    : "coolGray.300"
                  : "primary.500"
              }
              borderColor={
                complete
                  ? scheme === "dark"
                    ? "black"
                    : "coolGray.100"
                  : scheme === "dark"
                  ? "coolGray.600"
                  : "coolGray.300"
              }
              borderWidth="4"
              left="-62"
              bottom="45%"
            ></Box>
            <Box
              borderWidth={complete ? "1" : "2"}
              borderColor={
                complete
                  ? scheme === "dark"
                    ? "coolGray.600"
                    : "coolGray.300"
                  : scheme === "dark"
                  ? "gray.300"
                  : "black"
              }
              p="5"
              rounded="8"
            >
              <HStack alignItems="center">
                <Box flex="1">
                  <Text fontWeight="medium" fontSize="lg">
                    {exerciseName}
                  </Text>
                  <HStack alignItems="center" space="3" flexWrap="wrap" mb="-2">
                    <MaterialIcons
                      name="school"
                      size={18}
                      color={scheme === "dark" ? "white" : "black"}
                    />
                    <Text mt="3" fontSize="14">
                      {exerciseDuration}
                    </Text>
                  </HStack>
                  <HStack alignItems="center" space="3" flexWrap="wrap">
                    <MaterialCommunityIcons
                      name="lightbulb-on"
                      size={18}
                      color={scheme === "dark" ? "white" : "black"}
                    />
                    <Text mt="3" fontSize="14">
                      7 daily reflections
                    </Text>
                  </HStack>
                </Box>
                <MaterialCommunityIcons
                  name="chevron-right"
                  size={32}
                  color={scheme === "dark" ? "white" : "black"}
                />
              </HStack>
            </Box>
          </Pressable>
        ) : (
          <Box>
            <Box
              position="absolute"
              w="6"
              h="6"
              rounded="20"
              borderColor={scheme === "dark" ? "coolGray.600" : "coolGray.300"}
              backgroundColor={scheme === "dark" ? "black" : "coolGray.100"}
              borderWidth="4"
              left="-62"
              bottom="45%"
            ></Box>
            <Box
              borderWidth="1"
              borderColor={scheme === "dark" ? "coolGray.600" : "coolGray.300"}
              p="5"
              rounded="8"
            >
              <HStack alignItems="center" space="3" pr="6">
                <MaterialIcons
                  name="lock"
                  size={18}
                  color={scheme === "dark" ? "white" : "black"}
                />
                <Text fontWeight="medium" fontSize="lg">
                  {exerciseName}
                </Text>
              </HStack>
              <HStack alignItems="center" space="3" flexWrap="wrap" mb="-2">
                <MaterialIcons
                  name="school"
                  size={18}
                  color={scheme === "dark" ? "white" : "black"}
                />
                <Text mt="3" fontSize="14">
                  {exerciseDuration}
                </Text>
              </HStack>
              <HStack alignItems="center" space="3" flexWrap="wrap">
                <MaterialCommunityIcons
                  name="lightbulb-on"
                  size={18}
                  color={scheme === "dark" ? "white" : "black"}
                />
                <Text mt="3" fontSize="14">
                  7 daily reflections
                </Text>
              </HStack>
            </Box>
          </Box>
        )}
      </>
    );
  };
  return (
    <ScrollView>
      <Center py="6">
        <Image
          mt={4}
          source={image}
          width="450"
          height="260"
          alt="Procrastination App"
        />
        <Container py="6" w="full">
          <Heading mb="6">The psychology of procrastination</Heading>
          <HStack w="full">
            <Box
              flex="0"
              w="0"
              mr="12"
              borderStyle="solid"
              borderRadius="1"
              borderWidth="2"
              borderColor={scheme === "dark" ? "coolGray.600" : "coolGray.300"}
            ></Box>

            <VStack space="6" w="full" flex="1">
              {renderModule({
                onPress: () => {
                  navigation.push("Home", { exerciseNumber: 1 });
                },
                exerciseDuration: "5 minute lesson",
                exerciseName: "Understanding your procrastination",
                complete: uniqueDates.length >= 7,
              })}

              {renderModule({
                onPress:
                  uniqueDates.length >= 7
                    ? () => navigation.push("Home", { exerciseNumber: 2 })
                    : null,
                exerciseDuration: "8 minute lesson",
                exerciseName: "Uncover your core beliefs",
                complete: uniqueDates.length >= 14,
              })}

              {renderModule({
                onPress:
                  uniqueDates.length >= 14
                    ? () => navigation.push("Home", { exerciseNumber: 3 })
                    : null,
                exerciseDuration: "5 minute lesson",
                exerciseName: "Understand your reinforcement factors",
                complete: uniqueDates.length >= 21,
              })}

              {renderModule({
                onPress:
                  uniqueDates.length >= 21
                    ? () => navigation.push("Home", { exerciseNumber: 4 })
                    : null,
                exerciseDuration: "6 minute lesson",
                exerciseName: "Commit to change",
                complete: moduleCompletions.find((m) => m.number === 4),
              })}
            </VStack>
          </HStack>

          <Heading my="6">Overcoming procrastination</Heading>
          <HStack w="full">
            <Box
              flex="0"
              w="0"
              mr="12"
              borderStyle="solid"
              borderRadius="1"
              borderWidth="2"
              borderColor={scheme === "dark" ? "coolGray.600" : "coolGray.300"}
            ></Box>

            <VStack space="6" w="full" flex="1">
              {renderModule({
                onPress:
                  uniqueDates.length >= 21 &&
                  moduleCompletions.find((m) => m.number === 4)
                    ? () => navigation.push("Home", { exerciseNumber: 5 })
                    : null,
                exerciseDuration: "4 minute lesson",
                exerciseName: "Get past the starting threshold",
                complete: false,
              })}

              {renderModule({
                onPress: null,
                exerciseDuration: "5 minute lesson",
                exerciseName: "Use motivational self-talk",
                complete: false,
              })}

              {renderModule({
                onPress: null,
                exerciseDuration: "5 minute lesson",
                exerciseName: "Overcome rationalizations",
                complete: false,
              })}

              {renderModule({
                onPress: null,
                exerciseDuration: "6 minute lesson",
                exerciseName: "Manage discomfort",
                complete: false,
              })}

              {renderModule({
                onPress: null,
                exerciseDuration: "6 minute lesson",
                exerciseName: "Create a doing cycle",
                complete: false,
              })}
            </VStack>
          </HStack>

          <Heading my="6">Sustaining change</Heading>
          <HStack w="full">
            <Box
              flex="0"
              w="0"
              mr="12"
              borderStyle="solid"
              borderRadius="1"
              borderWidth="2"
              borderColor={scheme === "dark" ? "coolGray.600" : "coolGray.300"}
            ></Box>

            <VStack space="6" w="full" flex="1">
              {renderModule({
                onPress: null,
                exerciseDuration: "7 minute lesson",
                exerciseName: "Plan for setbacks",
                complete: false,
              })}

              {renderModule({
                onPress: null,
                exerciseDuration: "10 minute lesson",
                exerciseName: "Create a lasting habit",
                complete: false,
              })}
            </VStack>
          </HStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Journey;
