import * as React from "react";
import { Heading, Button, Center, Container, Text, VStack } from "native-base";

function WhatsToCome({ navigation, route }) {
  return (
    <Center>
      <Container py="3">
        <VStack space="3">
          <Heading>What's to come</Heading>
          <Text>
            Over the coming weeks you are going to learn a lot about the
            psychology of procrastination. Most importantly, you are going to
            learn a lot about your own psychology.
          </Text>
          <Text>
            Let’s start by learning about the areas of your life impacted by
            procrastination.
          </Text>
          <Button
            variant="outline"
            onPress={() => {
              navigation.push("UnderstandingProcrastination", { screen: "IdentifyBroad" });
            }}
          >
            Continue
          </Button>
        </VStack>
      </Container>
    </Center>
  );
}

export default WhatsToCome;
