import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  Checkbox,
  ScrollView,
  Image,
} from "native-base";

function Break({ navigation, route }) {
  return (
    <ScrollView>
      <Center py="3">
        <Container py="3">
          <VStack space="3">
            <Heading>How do you break the procrastination cycle?</Heading>
            <Text>
              Changing your behavior is like fixing a broken car. When your car
              breaks down, you don’t randomly start changing parts. A good
              mechanic first tries to understand why the car isn’t working!
            </Text>
            <Text>
              It is exactly the same with overcoming procrastination. 70% of the
              challenge is understanding what is driving it. The more familiar
              you are with your procrastination cycle, the easier it is to
              intervene.
            </Text>
            <Text>
              We are going to help you identify points in the cycle where you
              can intervene.
            </Text>
            <Button
              variant="outline"
              onPress={() => {
                navigation.push("Change", {
                  screen: "FourPoints",
                });
              }}
            >
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Break;
