/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-1",
    "aws_appsync_graphqlEndpoint": "https://7dcl2omld5alvejqgoyh3ypadm.appsync-api.us-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-afogk6s4bvgqtbkcgjfsl6wgze",
    "aws_cognito_identity_pool_id": "us-west-1:fc638b0e-aa1b-4f21-93ad-514235e31383",
    "aws_cognito_region": "us-west-1",
    "aws_user_pools_id": "us-west-1_5R3ae7lbe",
    "aws_user_pools_web_client_id": "2eg6fnaf2hron557qkutr1rfvh",
    "oauth": {
        "domain": "ju8uv7kfomr4-prod.auth.us-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:19006/,https://app.procrastinationapp.com/,exp://192.168.1.99:19000/,exp://192.168.1.99:19000/--/,procrastinationapp://",
        "redirectSignOut": "http://localhost:19006/,https://app.procrastinationapp.com/,exp://192.168.1.99:19000/,exp://192.168.1.99:19000/--/,procrastinationapp://",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE",
        "APPLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
