import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  Checkbox,
  ScrollView,
  Image,
} from "native-base";

function Notice({ navigation, route }) {
  return (
    <ScrollView h={800}>
      <Center py="3">
        <Container py="3">
          <VStack space="3">
            <Heading>Noticing core beliefs decreases their power</Heading>
            <Text>As you notice these unhelpful beliefs and the thoughts associated with them, they begin to loosen their grip on you.</Text>
            <Text>That means that it becomes slightly easier to overcome procrastination and get started!</Text>
            <Button
              variant="outline"
              onPress={() => {
                navigation.push("Beliefs", {
                  screen: "Mission",
                });
              }}
            >
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Notice;