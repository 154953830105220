import * as React from "react";
import {
  Checkbox,
  Heading,
  Button,
  Center,
  Container,
  VStack,
  Text,
  ScrollView,
  KeyboardAvoidingView,
  TextArea,
} from "native-base";
import { useStore } from "../../state";

function PositiveOutcome({ navigation, route }) {
  const newLogEntry = useStore((state) => state.newLogEntry);
  const setNewLogEntry = useStore((state) => state.setNewLogEntry);
  const positiveOutcomes = useStore((state) => state.positiveOutcomes);
  const setPositiveOutcomes = useStore((state) => state.setPositiveOutcomes);
  const [showInputField, setShowInputField] = React.useState(false)
  const [newOutcome, setNewOutcome] = React.useState("")
  const complete = (positiveOutcome: string, isCustom?: boolean) => {
    setNewLogEntry({ ...newLogEntry, positiveOutcome });
    if (isCustom) {
      setPositiveOutcomes([...positiveOutcomes, positiveOutcome]);
    }
    navigation.push("Reflect", { screen: "NegativeOutcome" });
  };
  return (
    <ScrollView h={800}>
      <Center>
        <Container py="3">
          <VStack space="3">
            <Heading size="lg" mt="3">
              What is a positive outcome of putting off the task?
            </Heading>
            <Text>Select from the common outcomes or add your own.</Text>
            {showInputField ? (
              <KeyboardAvoidingView behavior="padding">
                <VStack space="3">
                  <TextArea
                    placeholder="Describe the positive outcome..."
                    autoCompleteType="text"
                    value={newOutcome}
                    onChangeText={setNewOutcome}
                    maxW="500"
                  />
                  <Button onPress={() => complete(newOutcome, true)}>
                    Submit
                  </Button>
                  <Button
                    variant="outline"
                    onPress={() => setShowInputField(false)}
                  >
                    Cancel
                  </Button>
                </VStack>
              </KeyboardAvoidingView>
            ) : (
              <>
                {[
                  ...positiveOutcomes,
                  "I enjoy the activities I’m doing in place of my important task.",
                  "I’m temporarily relieved from an uncomfortable sensation.",
                  "I’m able to stick to one of my core beliefs.",
                ]
                  .filter((outcome) => outcome)
                  .map((outcome) => (
                    <Button key={outcome} onPress={() => complete(outcome)}>
                      {outcome}
                    </Button>
                  ))}
                <Button onPress={() => setShowInputField(true)}>
                  Add an outcome +
                </Button>
              </>
            )}
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default PositiveOutcome;