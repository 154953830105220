import * as React from "react";
import {
  Box,
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  Checkbox,
  ScrollView,
} from "native-base";
import { useStore } from "../../state";

function IdentifyExcuses({ navigation, route }) {
  const setExcuses = useStore((state) => state.setExcuses);
  return (
    <ScrollView>
      <Center w="full">
        <Container py="3" w="full">
          <VStack space="3" w="full">
            <Heading mt="3">
              What rationalisations for procrastinating do you use?
            </Heading>
            <VStack space="3">
              <Text>Select the common excuses that you use:</Text>
              <Checkbox.Group
                defaultValue={[]}
                accessibilityLabel="pick excuses"
                onChange={(values) => {
                  setExcuses(values || []);
                }}
              >
                  {[
                    "I’m too tired, I’ll do it tomorrow",
                    "I don’t have everything I need, I can’t start it now",
                    "I don’t have enough time to do it all, so I will wait until I do",
                    "I won’t get much done, so I’ll just leave it for now",
                    "It is better to do it when I am in the mood or feeling inspired",
                    "I will miss out on the fun happening now, I can do it another time",
                    "I will do it once this other thing is finished",
                    "I’ve got to organise my desk/kitchen/laundry, etc first",
                    "I have plenty of time, so I can do it later",
                    "I work better when I am stressed, so I will leave it to the last minute",
                    "It might not be good enough, so why bother doing it",
                    "Working on it today won’t make any difference",
                    "I don’t even know where to start, or how to make progress",
                    "I’ll feel more like doing it after another cup of coffee",
                    "Now it’s too late to start. I’ll just do it tomorrow",
                  ].map((item) => (
                      <Checkbox value={item}>{item}</Checkbox>
                  ))}
              </Checkbox.Group>
            </VStack>
            <Button
              mt={3}
              variant="outline"
              onPress={() => {
                navigation.push("UnderstandingProcrastination", {
                  screen: "Explanation",
                });
              }}
            >
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default IdentifyExcuses;
