import * as React from "react";
import {
  Box,
  Heading,
  Button,
  Center,
  Container,
  Text,
  TextInput,
  VStack,
  ScrollView,
} from "native-base";
import { useColorScheme } from "react-native";
import { useStore } from "../../../state";

function Worthwhile({ navigation, route }) {
  const readinessForChange = useStore((state) => state.readinessForChange);
  const scheme = useColorScheme();
  return (
    <ScrollView>
      <Center py="3">
        <Container py="3">
          <VStack space="3">
            <Heading>Belief that change is worthwhile</Heading>
            {readinessForChange.worthwhile > 10 ? (
              <>
                <Text>
                  You think putting effort into changing your procrastination
                  habits is worthwhile. That is brilliant! 😄
                </Text>
              </>
            ) : (
              <Text>
                You think putting effort into changing your procrastination
                habits is probably not worthwhile.
              </Text>
            )}
            <Text>
              We think that learning the skill of overcoming procrastination is
              one of life's highest leverage skills! It can really have a big
              impact on your life.
            </Text>
            <Text>
              Believe it or not, there are over 1,000 published studies on
              interventions to overcome procrastination! 🤯 35 of those studies
              were large scale randomized control trials - the strongest form of
              evidence in science!
            </Text>
            <Text>
              The take home message from all that research is that
              procrastination interventions work!
            </Text>
            <Text>Here are some of the key findings:</Text>
            <Text>
              • Cognitive-behavior therapy techniques showed the largest
              reduction in procrastination. Good news for you is that is what we
              have based the design of this app on!
            </Text>
            <Text>
              • After basic training, follow-up measurements show that changes
              in procrastination persisted for over 365 days. In fact, people
              improved over time!
            </Text>
            <Text>
              Because of all that👆we think it is worth trying! But of course
              that is up to you. To help you weigh up if it worthwhile
              continuing, we recommend answering these two questions:
            </Text>
            <Box
              borderWidth="1"
              borderColor={scheme === "dark" ? "coolGray.600" : "coolGray.300"}
              backgroundColor={
                scheme === "dark" ? "coolGray.600" : "coolGray.300"
              }
              p="5"
              rounded="8"
            >
              <Text mb="3">
                If I keep using this app and follow the recommendations...
                <Text bold>what will be good?</Text>
              </Text>
              <Text>
                If I keep using this app and follow the recommendations...
                <Text bold>what will be bad?</Text>
              </Text>
            </Box>
            <Text>
              Looking at your responses, do you think the good that will come
              from trying outweighs the costs of trying?
            </Text>
            <Text>
              If you commit to trying, you can periodically review your
              responses to these questions as a way of increasing your
              motivation to keep going!
            </Text>
            <Button
              variant="outline"
              onPress={() => {
                navigation.goBack();
              }}
            >
              Back
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Worthwhile;
