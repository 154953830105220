import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  TextArea,
  VStack,
  Checkbox,
  ScrollView,
  KeyboardAvoidingView,
  Image,
} from "native-base";
import { useStore } from "../../state";

function Pressure({ navigation, route }) {
  const sensations = useStore((state) => state.logEntries).map(entry => entry.sensation);
  return (
    <ScrollView>
      <Center py="3">
        <Container py="3">
          <VStack space="3">
            <Heading>Mounting pressure</Heading>
            <Text>The more you put something off, the more tasks pile up around you. As things pile up, pressure starts to mount, which makes you feel overwhelmed.</Text>
            <Text>When everything becomes too much, you want to escape - motivating you to procrastinate even more.</Text>
            <Button
              variant="outline"
              onPress={() => {
                navigation.push("ReinforcementFactors", {
                  screen: "Mission",
                });
              }}
            >
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Pressure;