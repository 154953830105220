import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  Checkbox,
  ScrollView,
  Image,
} from "native-base";

function Discomfort({ navigation, route }) {
  return (
    <ScrollView h={800}>
      <Center py="3">
        <Container py="3">
          <VStack space="3">
            <Heading>Why do certain tasks cause us discomfort? </Heading>
            <Text>We’ve learnt that procrastination is a strategy for dealing with the discomfort associated with certain tasks.</Text>
            <Text>
                That begs the question, why do certain tasks cause you discomfort? 
            </Text>
            <Button
              variant="outline"
              onPress={() => {
                navigation.push("Beliefs", {
                  screen: "CoreBeliefs",
                });
              }}
            >
              Continue
            </Button>
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Discomfort;