import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  TextArea,
  VStack,
  Text,
  ScrollView,
  KeyboardAvoidingView
} from "native-base";
import { useStore } from "../../state";

function Excuse({ navigation, route }) {
  const excuses = useStore((state) => state.excuses);
  const setExcuses = useStore((state) => state.setExcuses);
  const newLogEntry = useStore((state) => state.newLogEntry);
  const setNewLogEntry = useStore((state) => state.setNewLogEntry);
  const [newExcuse, setNewExcuse] = React.useState("");
  const [showInputField, setShowInputField] = React.useState(false);
  const complete = (excuse: string, isCustom?: boolean) => {
    setNewLogEntry({ ...newLogEntry, excuse });
    if (isCustom) {
      setExcuses([...excuses, excuse]);
    }
    navigation.push("Reflect", { screen: "Reflections" });
  };
  return (
    <ScrollView>
      <Center>
        <Container py="3">
          <VStack space="3">
            <Heading size="lg" mt="3">
              What rationalisations are you using?
            </Heading>
            <Text>
              Select from the common excuses you identified, or add a new one.
            </Text>
            {showInputField ? (
            <KeyboardAvoidingView behavior="padding">
              <VStack space="3">
                <TextArea
                  placeholder="Describe the excuse..."
                  autoCompleteType="text"
                  value={newExcuse}
                  onChangeText={setNewExcuse}
                  maxW="500"
                />
                <Button onPress={() => complete(newExcuse, true)}>
                  Submit
                </Button>
                <Button
                  variant="outline"
                  onPress={() => setShowInputField(false)}
                >
                  Cancel
                </Button>
              </VStack>
            </KeyboardAvoidingView>
            ) : (
              <>
                {excuses.map((excuse) => (
                  <Button key={excuse} onPress={() => complete(excuse)}>
                    {excuse}
                  </Button>
                ))}
                <Button onPress={() => setShowInputField(true)}>
                  {excuses.length > 0 ? "Other excuse +" : "Add an excuse +"}
                </Button>
              </>
            )}
          </VStack>
        </Container>
      </Center>
    </ScrollView>
  );
}

export default Excuse;
