import * as React from "react";
import {
  Heading,
  Button,
  Center,
  Container,
  Text,
  VStack,
  TextArea,
  KeyboardAvoidingView,
} from "native-base";
import { useStore } from "../../state";

function IdentifySpecific({ navigation, route }) {
  const specificTasks = useStore((state) => state.specificTasks);
  const setSpecificTasks = useStore((state) => state.setSpecificTasks);
  const [newTask, setNewTask] = React.useState("");
  const [showInputField, setShowInputField] = React.useState(false);
  const complete = (task: string) => {
    setSpecificTasks([...specificTasks, task]);
    setNewTask("");
    setShowInputField(false);
  };
  const tasks = useStore((state) => state.tasks);
  return (
    <Center>
      <Container py="3">
        <VStack space="3">
          <Heading>Identify specific tasks &amp; goals</Heading>
          <Text>Great! Here's your list:</Text>
          <VStack space="1">
            {tasks.map((task) => (
              <Text italic key={task}>
                • {task}
              </Text>
            ))}
          </VStack>
          <Text>
            Now let's get more specific. Across these areas, briefly describe
            the 1-4 tasks or goals where procrastination causes you the most
            trouble.
          </Text>
          {showInputField ? (
            <KeyboardAvoidingView behavior="padding">
              <VStack space="3">
                <TextArea
                  placeholder="Describe the task..."
                  autoCompleteType="text"
                  value={newTask}
                  onChangeText={setNewTask}
                  w="300"
                  maxW="500"
                />
                <Button onPress={() => complete(newTask)}>Submit</Button>
                <Button
                  variant="outline"
                  onPress={() => setShowInputField(false)}
                >
                  Cancel
                </Button>
              </VStack>
            </KeyboardAvoidingView>
          ) : (
            <>
              {specificTasks.map((task, index) => (
                <Text bold key={task}>
                  {index + 1}: {task}
                </Text>
              ))}
              <Button onPress={() => setShowInputField(true)}>
                Add a task +
              </Button>
              <Button
                variant="outline"
                onPress={() => {
                  navigation.push("UnderstandingProcrastination", {
                    screen: "IdentifyDistractions",
                  });
                }}
              >
                Continue
              </Button>
            </>
          )}
        </VStack>
      </Container>
    </Center>
  );
}

export default IdentifySpecific;
